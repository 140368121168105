import { AuthProvider as AuthProviderInterface } from "ra-core";
import { getCurrentUser, signOut, signInWithRedirect, fetchAuthSession } from "aws-amplify/auth";
import { READONLY_ROLE } from "@/Settings/roles";

export interface AuthProviderOptions {
  authGroups?: string[];
}

const defaultOptions = {
  authGroups: [],
};

class AuthProvider {
  public authGroups: string[];

  public constructor(options?: AuthProviderOptions) {
    this.authGroups = options?.authGroups || defaultOptions.authGroups;
  }

  public login = async (): Promise<any> => {
    signInWithRedirect({
      provider: {
        custom: "Arkema",
      },
      customState: "STATE",
    });
    return Promise.resolve();
  };

  public logout = (): Promise<any> => {
    return signOut();
  };

  public checkAuth = async (): Promise<void> => {
    try {
      await getCurrentUser();
    } catch (error) {
      signInWithRedirect({
        provider: {
          custom: "Arkema",
        },
        customState: "STATE",
      });
      return Promise.reject("Not authenticated");
    }

    if (this.authGroups.length === 0) {
      return Promise.resolve();
    }

    try {
      const userGroups = (await fetchAuthSession()).tokens?.accessToken.payload[
        "cognito:groups"
      ] as string[];
      if (!userGroups) {
        return Promise.reject("Unauthorized");
      }

      for (const group of userGroups) {
        if (this.authGroups.includes(group)) {
          return Promise.resolve();
        }
      }
      return Promise.reject("Unauthorized");
    } catch (error) {
      return Promise.reject("Unauthorized");
    }
  };

  public checkError = (): Promise<void> => {
    return Promise.resolve();
  };

  public getPermissions = async (): Promise<string[]> => {
    const session = await fetchAuthSession();
    const groups = session.tokens?.accessToken.payload["cognito:groups"] as string[];
    if (groups) {
      //default to ReadOnly
      groups.push(READONLY_ROLE);
      return Promise.resolve(groups);
    }
    return Promise.reject();
  };

  public getIdentity = async (): Promise<any> => {
    const { username } = await getCurrentUser();
    return Promise.resolve({
      id: username,
      fullName: username,
    });
  };
}

export function buildAuthProvider(options?: AuthProviderOptions): AuthProviderInterface {
  const authProvider = new AuthProvider(options);

  return {
    login: authProvider.login,
    logout: authProvider.logout,
    checkAuth: authProvider.checkAuth,
    checkError: authProvider.checkError,
    getPermissions: authProvider.getPermissions,
    getIdentity: authProvider.getIdentity,
  };
}
