import { ConditionalInput } from "@/Components/ConditionalForm";
import { BooleanInput, required, TextInput, useTranslate } from "react-admin";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";

const Retry = () => {
  const translate = useTranslate();
  return (
    <StyledContainer className={classes.formArea}>
      <BooleanInput
        source="retry.retryAutoSurcharge"
        defaultValue={false}
        label={translate("resources.configuration.fields.retry")}
      />
      <ConditionalInput validate={(values: any) => values?.retry?.retryAutoSurcharge === true}>
        <TextInput
          sx={{ marginLeft: "0.75rem" }}
          defaultValue={3}
          source="retry.retryLimit"
          validate={[required()]}
          label={translate("resources.configuration.fields.retry_auto_frequence")}
        />
        <TextInput
          sx={{ marginLeft: "1rem" }}
          defaultValue={3000}
          source="retry.retryDelay"
          validate={[required()]}
          label={translate("resources.configuration.fields.retry_auto_ecart")}
        />
      </ConditionalInput>
    </StyledContainer>
  );
};

export default Retry;
