import Icon from "@mui/icons-material/Transform";
import List from "./List";
import Show from "./Show";

const TransformationType = {
  list: List,
  show: Show,
  icon: Icon,
};

export default TransformationType;
