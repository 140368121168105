import { Query } from "@tanstack/react-query";
export const preventAutoFetchOptions = (enable?: boolean, refetchOnMount?: boolean) => {
  return {
    enabled: (query: Query) => {
      try {
        if (enable) return enable;
        //eslint-disable-next-line
        const [_resource, _method, params] = JSON.parse(query.queryHash);
        const { filter = {} } = params;
        const { searchId, ...activeFilters } = filter;
        const shouldEnable = Object.keys(activeFilters).length > 0;
        return shouldEnable;
      } catch (error) {
        return false;
      }
    },
    refetchOnMount: (query: Query) => {
      try {
        if (refetchOnMount) return refetchOnMount;
        //eslint-disable-next-line
        const [_resource, _method, params] = JSON.parse(query.queryHash);
        const { filter = {} } = params;
        const { searchId, ...activeFilters } = filter;
        const shouldRefresh = Object.keys(activeFilters).length > 0;
        return shouldRefresh;
      } catch (error) {
        return false;
      }
    },
    refetchOnWindowFocus: false,
  };
};
