import {
  TextField,
  BooleanField,
  DateField,
  ReferenceField,
  ArrayField,
  Datagrid,
  RichTextField,
  Labeled,
} from "react-admin";
import { ConnectionTitle } from ".";
import ShowBase from "../BaseModel/Show";
import { ParametersFields } from "./Parameters";
import { Clear, Done } from "@mui/icons-material";
import { CopyToClipboard, classes } from "@/Components/CustomCopyToClipboard";

const ShowForm = (props: any) => (
  <ShowBase {...props} title={<ConnectionTitle />}>
    <Labeled label="Id">
      <CopyToClipboard className={classes.field}>
        <TextField source="id" />
      </CopyToClipboard>
    </Labeled>

    <BooleanField source="enabled" />
    <BooleanField
      source="auto_disabled"
      TrueIcon={Clear}
      FalseIcon={Done}
      label={"Auto Enabled"}
      sortable={false}
    />
    <ReferenceField source="type" reference="connectionType" link="show">
      <TextField source="name" />
    </ReferenceField>
    <ParametersFields />
    <ReferenceField source="created_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="created_at" showTime={true} />
    <ReferenceField source="updated_by" reference="user" link="show">
      <TextField source="name" />
    </ReferenceField>
    <DateField source="updated_at" showTime={true} />
    <BooleanField source="maintenance_enabled" />
    <ArrayField source="maintenance_schedules">
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <TextField source="start_schedule" />
        <TextField source="end_schedule" />
      </Datagrid>
    </ArrayField>
    <RichTextField source="retry.retryDelay" />
    <RichTextField source="retry.retryLimit" />
  </ShowBase>
);

export default ShowForm;
