import { useCallback, useEffect, useRef, useState } from "react";
import { useListContext, usePermissions, useRecordContext, useTranslate } from "react-admin";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import OpenLogsButton from "../../CustomOpenLogsAction/OpenLogsAction";
import DownloadButton from "../../CustomDownloadFileAction/DownloadFileAction";
import { useSharedListChecked } from "@/Components/Models/Tracking/SharedState";
import { authorizeAction, authorizeDownload } from "@/Settings/roles";
import { get } from "@aws-amplify/api";
import { CircularProgress } from "@mui/material";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";
import {
  StyledTableCell,
  StyledTableRow,
  classes as FieldClasses,
} from "@/Components/CustomStyled/Fields";

export interface TrackingFileData {
  id: string;

  // execution data
  executionCorrelationId: string;
  executionStartDateTimestamp?: number;
  executionEndDateTimestamp?: number;
  executionStartDate?: string;
  executionEndDate?: string;

  // file data
  fileName: string;
  progression?: string;
  technicalProgression?: string;
  fileStartDateTimestamp?: number;
  fileEndDateTimestamp?: number;
  fileStartDate?: string;
  fileEndDate?: string;
  errorDetails?: string;

  // targets
  targetName?: string;
}

const calculateColorByStatus = (progression: string, filesListCss: typeof FieldClasses) => {
  switch (progression) {
    case "ENDED":
      return filesListCss.greenRow;
    case "ERROR":
      return filesListCss.redRow;
    case "CANCELLED":
      return filesListCss.darkgrayRow;
    case "ABANDONED":
      return filesListCss.yellowRow;
    case "IN_PROGRESS":
      return filesListCss.orangeRow;
    case "IN_QUEUE":
      return filesListCss.lightblueRow;
    default:
      return filesListCss.grayRow;
  }
};

export const disabledSelectionFilesStatus = ["ENDED", "ABANDONED"];

export interface FileMetaData {
  fileId: string;
  executionCorrelationId: string;
  executionStartDateTimestamp: number;
  fileName: string;
  fileStatus: string;
  targets: number[];
}

export const ExecutionFiles = new Map<string, FileMetaData[]>();

const InitFiles = (props: any) => {
  const { record, data } = props;
  const correlationId = record.id;

  const fileTargets = (fileTarget: string | null): number[] => {
    if (fileTarget !== null) {
      return [Number(fileTarget)];
    }
    return record.targets
      ? Object.keys(record.targets).map((targetId: string) => Number(targetId))
      : [];
  };
  let fileMetaData: FileMetaData[] = [];
  fileMetaData = data.map((file: TrackingFileData) => {
    return {
      fileId: file.id,
      executionCorrelationId: file.executionCorrelationId,
      executionStartDateTimestamp: file.executionStartDateTimestamp,
      fileName: file.fileName,
      fileStatus: file.progression,
      targets: fileTargets(file.targetName || null),
    };
  });
  ExecutionFiles.set(correlationId, fileMetaData);
  return <></>;
};

const FileList = () => {
  const record = useRecordContext() as Record<string, any>;
  const { configurationCode, configurationName } = record;

  const { filterValues } = useListContext();
  const [rows, setRows] = useState(new Array<any>());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setNextPage] = useState(true);
  const { permissions, isLoading } = usePermissions();
  const { listChecked, updateListChecked } = useSharedListChecked();
  const translate = useTranslate();

  const update = (event: any, fileId: string) => {
    if (event.target.checked) {
      updateListChecked([...listChecked, fileId]);
    } else {
      updateListChecked(listChecked.filter((element) => element !== fileId));
    }
  };

  const fetchFilesDetails = useCallback(async () => {
    setLoading(true);
    try {
      const { body } = await get({
        apiName: "api",
        path: `/tracking/${configurationName}${configurationCode}`,
        options: {
          queryParams: {
            filter: JSON.stringify(filterValues),
            pagination: JSON.stringify({ page: page, perPage: 500 }),
            sort: JSON.stringify({ field: "fileEndDateTimestamp", order: "DESC" }),
          },
        },
      }).response;
      const data = (await body.json()) as Record<string, any>;
      if (!data.Items.length) {
        setNextPage(false);
      }
      setRows((rows) => [...rows, ...data.Items]);
      setPage((page) => page + 1);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [filterValues, page, configurationCode, configurationName]);

  if (error) {
    return <p>ERROR</p>;
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <>
      <StyledContainer className={classes.filesContainer}>
        <InitFiles record={record} data={rows}></InitFiles>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate("resources.tracking.files.action")}</TableCell>
              <TableCell align="left">{translate("resources.tracking.files.fileName")}</TableCell>
              <TableCell align="left">
                {translate("resources.tracking.files.executionStartDate")}
              </TableCell>
              <TableCell align="left">
                {translate("resources.tracking.files.executionEndDate")}
              </TableCell>
              <TableCell align="left">{translate("resources.tracking.files.status")}</TableCell>
              <TableCell align="left">
                {translate("resources.tracking.files.targetConnexionName")}
              </TableCell>
              <TableCell align="left">
                {translate("resources.tracking.files.errorDetails")}
              </TableCell>
              <TableCell align="left">{translate("resources.tracking.files.accessLogs")}</TableCell>
              <TableCell align="left">
                {translate("resources.tracking.files.downloadFile")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => {
              const fileId = row.id;
              return (
                <StyledTableRow
                  key={fileId + Math.random().toString()}
                  className={calculateColorByStatus(row.progression, FieldClasses)}
                >
                  <StyledTableCell className={FieldClasses.textColor}>
                    <Checkbox
                      disabled={disabledSelectionFilesStatus.includes(row.progression)}
                      name={row.fileName}
                      value={record.id}
                      id={fileId}
                      onChange={(event) => {
                        update(event, fileId);
                      }}
                      checked={listChecked.some((element) => element === fileId)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    className={FieldClasses.textColor}
                    title={row.executionCorrelationId}
                    align="left"
                  >
                    {row.fileName}
                  </StyledTableCell>
                  <StyledTableCell className={FieldClasses.textColor} align="left">
                    {row.fileStartDate ? row.fileStartDate : "-"}
                  </StyledTableCell>
                  <StyledTableCell className={FieldClasses.textColor} align="left">
                    {row.fileEndDate ? row.fileEndDate : "-"}
                  </StyledTableCell>
                  <StyledTableCell className={FieldClasses.textColor} align="left">
                    {row.progression}
                  </StyledTableCell>
                  <StyledTableCell
                    className={FieldClasses.textColor}
                    title={row.targetName ? row.targetName : "-"}
                    align="left"
                  >
                    {row.targetConnexionName ? row.targetConnexionName : "-"}
                  </StyledTableCell>
                  <StyledTableCell className={FieldClasses.textColor} align="left">
                    {row.errorDetail ? row.executionEndDate : "-"}
                  </StyledTableCell>
                  <TableCell align="center">
                    {authorizeAction(permissions, "tracking", "log") ? (
                      <OpenLogsButton record={row} />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {authorizeDownload(permissions, [
                      row.sourceApplication,
                      row.targetApplication,
                    ]) ? (
                      <DownloadButton
                        record={{
                          ...row,
                          configurationId: `${configurationName}${configurationCode}`,
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        <NextPage hasNextPage={hasNextPage} next={fetchFilesDetails} isLoading={loading} />
      </StyledContainer>
    </>
  );
};

function NextPage(props: any) {
  let { next, hasNextPage, isLoading } = props;
  const t = useTranslate();
  const observerRef = useRef(null);
  const getMore = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        next();
      }
    },
    [next, hasNextPage]
  );

  useEffect(() => {
    const watchElement = observerRef.current;
    if (!watchElement) return;
    const observer = new IntersectionObserver(getMore);
    observer.observe(watchElement);
    return () => observer.unobserve(watchElement);
  }, [getMore]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px" }}>
      {isLoading ? <CircularProgress size={"2rem"} /> : ""}
      {!hasNextPage ? (
        <p>{t("resources.tracking.files.allFilesRetrieved")}</p>
      ) : (
        <div style={{ width: "10px", height: "10px" }} ref={observerRef}></div>
      )}
    </div>
  );
}

export default FileList;
