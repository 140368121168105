import { Fragment, useState } from "react";
import {
  Button,
  Confirm,
  useRefresh,
  useNotify,
  useUnselectAll,
  useDataProvider,
} from "react-admin";
import { useMutation } from "@tanstack/react-query";
import EnableIcon from "@mui/icons-material/Done";
import DisableIcon from "@mui/icons-material/Cancel";
import * as React from "react";
import SelectEnvDialog from "./SelectEnvDialog";
import { ContentCopy } from "@mui/icons-material";

const CustomEnableButton = ({
  selectedIds,
  resource,
}: {
  selectedIds: string[];
  resource: string;
}) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const [openEnv, setOpenEnv] = React.useState(false);
  const [envValue, setEnvValue] = React.useState("");

  const { mutate: enableMutate, isPending } = useMutation({
    mutationFn: () => {
      return dataProvider.enable(resource, {
        ids: selectedIds,
        enabled: mode,
      });
    },
    onSuccess: () => {
      refresh();
      notify("common.actions.enable.success", { type: "info", messageArgs: { resource } });
      unselectAll();
    },
    onError: () =>
      notify("common.actions.enable.failure", { type: "warning", messageArgs: { resource } }),
  });
  const { mutate: getOneWithEnvMutate } = useMutation({
    mutationFn: () => {
      return dataProvider.getOneWithEnv(resource, {
        id: selectedIds,
        env: envValue,
      });
    },
    onSuccess: () => {
      refresh();
      notify("common.actions.copyToEnv.success", { type: "info", messageArgs: { resource } });
      unselectAll();
    },
    onError: (error: any) => {
      let detail;
      if (error.response instanceof Object) {
        detail = error.response.data.detail;
      } else if (error.message) {
        detail = error.message;
      } else {
        detail = "unkown error";
      }
      notify("common.actions.copyToEnv.failure", {
        type: "warning",
        messageArgs: { error: detail },
      });
    },
  });

  const handleClick = (enable: boolean) => {
    setMode(enable);
    setOpen(true);
  };
  const handleDialogClose = () => {
    setMode(false);
    setOpen(false);
  };

  const handleConfirm = () => {
    enableMutate();
    setOpen(false);
  };

  const handleClickOpenEnv = () => {
    setOpenEnv(true);
  };
  const handleClickEnvDialogClose = () => {
    setOpenEnv(false);
  };

  const OnListItemClick = (value: any) => {
    setEnvValue(value);
  };
  const validateEnv = () => {
    if (envValue !== "") {
      getOneWithEnvMutate();
    }
  };

  return (
    <>
      <Button
        aria-test={"enable-btn"}
        label="common.actions.enable.btn.enabled"
        onClick={() => handleClick(true)}
      >
        <EnableIcon />
      </Button>
      <Button
        aria-test={"disable-btn"}
        label="common.actions.enable.btn.disabled"
        onClick={() => handleClick(false)}
      >
        <DisableIcon />
      </Button>
      <Button
        aria-test={"copyToEnv-btn"}
        label="common.actions.copyToEnv.btn"
        onClick={() => handleClickOpenEnv()}
      >
        <ContentCopy />
      </Button>

      <Confirm
        isOpen={open}
        loading={isPending}
        title="common.actions.enable.title"
        content="common.actions.enable.content"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />

      <SelectEnvDialog
        selectedValue={"env"}
        open={openEnv}
        onClose={handleClickEnvDialogClose}
        handleListItemClick={OnListItemClick}
        validate={validateEnv}
      />
    </>
  );
};

export default CustomEnableButton;
