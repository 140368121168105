import { Fragment } from "react";
import {
  Button,
  useRefresh,
  useNotify,
  useUnselectAll,
  useTranslate,
  useDataProvider,
} from "react-admin";
import { useMutation } from "@tanstack/react-query";
import { useShareableDeleteStepState } from "./SharedState";
import { useBetween } from "use-between";
import Delete from "@mui/icons-material/Delete";

type DeleteWithCheckingType = "connection" | "application";

const BulkDeleteWithChecking = ({
  selectedIds,
  resource,
}: {
  selectedIds: string[];
  resource: DeleteWithCheckingType;
}) => {
  const dataProvider = useDataProvider();
  const { deleteStep, updateDeleteStep } = useBetween(useShareableDeleteStepState);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll(resource);
  const notificationDurationInMs = 30000;
  const translate = useTranslate();
  const { mutate } = useMutation({
    mutationFn: async () => {
      return dataProvider.deleteWithChecking(resource, {
        ids: selectedIds,
      });
    },
    onSuccess: (results) => {
      updateDeleteStep("DELETE");
      refresh();
      if (results?.data?.undeleted?.length > 0) {
        notify(`resources.${resource}.deleteActions.unDeleted`, {
          type: "warning",
          messageArgs: {
            undeletedCount: results.data.undeleted.length,
            deletedCount: results.data.deleted?.length ?? 0,
          },
          autoHideDuration: notificationDurationInMs,
        });
      }

      if (results?.data?.deleted?.length > 0) {
        notify(`resources.${resource}.deleteActions.allDeleted`, {
          type: "success",
          autoHideDuration: notificationDurationInMs,
        });
      }
      unselectAll();
    },
    onError: () =>
      notify(`resources.${resource}.deleteActions.error`, {
        type: "error",
        autoHideDuration: notificationDurationInMs,
      }),
  });

  const startDeletingProcess = () => {
    updateDeleteStep("DELETING");
    mutate();
  };

  return (
    <Fragment>
      <Button
        aria-test={"deleteWithChecking-btn"}
        label={translate(
          deleteStep === "DELETE"
            ? `resources.${resource}.deleteActions.deleteLabel`
            : `resources.${resource}.deleteActions.deletingLabel`
        )}
        disabled={deleteStep !== "DELETE"}
        onClick={() => startDeletingProcess()}
      >
        <Delete />
      </Button>
    </Fragment>
  );
};

export default BulkDeleteWithChecking;
