import { InputProps, useRecordContext } from "react-admin";
import {
  Fields as FTPFields,
  Inputs as FTPInputs,
  FTP_PARAMETERS,
  FTPS_PARAMETERS,
} from "./FTPParameters";
import {
  Fields as SFTPFields,
  Inputs as SFTPInputs,
  PARAMETERS as SFTP_PARAMETERS,
  validate as SFTPValidate,
} from "./SFTPParameters";
import {
  Fields as S3Fields,
  Inputs as S3Inputs,
  PARAMETERS as S3_PARAMETERS,
} from "./S3Parameters";
import {
  Fields as HTTPFields,
  Inputs as HTTPInputs,
  PARAMETERS as HTTP_PARAMETERS,
} from "./HTTPParameters";

type ConnectionType = "S3" | "FTP" | "FTPS" | "SFTP" | "HTTP";

const PARAMETERS_BY_TYPE: { [key in ConnectionType]: string[] } = {
  S3: S3_PARAMETERS,
  FTP: FTP_PARAMETERS,
  FTPS: FTPS_PARAMETERS,
  SFTP: SFTP_PARAMETERS,
  HTTP: HTTP_PARAMETERS,
};

const customSx = {
  display: "block",
  width: "77%",
  minWidth: "500px",
  "& .MuiInputBase-root": {
    width: "100%",
  },
};

const ParametersInputs = ({ parameterType }: { parameterType: string } & InputProps) => {
  if (!parameterType) return null;

  switch (parameterType) {
    case "FTP":
      return <FTPInputs customSx={customSx} disableReject={true} />;
    case "FTPS":
      return <FTPInputs customSx={customSx} disableReject={false} />;
    case "SFTP":
      return <SFTPInputs customSx={customSx} />;
    case "S3":
      return <S3Inputs customSx={customSx} />;
    case "HTTP":
      return <HTTPInputs customSx={customSx} />;
    default:
      return null;
  }
};

const ParametersFields = () => {
  const record = useRecordContext();
  if (!record) return null;
  const { type } = record;

  switch (type) {
    case "FTP":
      return <FTPFields customSx={customSx} disableReject={true} />;
    case "FTPS":
      return <FTPFields customSx={customSx} disableReject={false} />;
    case "SFTP":
      return <SFTPFields customSx={customSx} />;
    case "S3":
      return <S3Fields customSx={customSx} />;
    case "HTTP":
      return <HTTPFields customSx={customSx} />;
    default:
      return null;
  }
};

const ParametersValidation = (values: any) => {
  const { type } = values;
  if (!type) return;

  switch (type) {
    case "SFTP":
      return SFTPValidate(values);
    default:
      return;
  }
};

function filterByType(type: ConnectionType, parameters: { [key: string]: any }) {
  const filteredParameters: { [key: string]: any } = {};
  const authoizedKeys = PARAMETERS_BY_TYPE[type];

  for (const key in parameters) {
    if (authoizedKeys.includes(key) && parameters[key] !== undefined && parameters[key] !== null) {
      filteredParameters[key] = parameters[key];
    }
  }

  return filteredParameters;
}

export {
  ParametersInputs,
  ParametersFields,
  ParametersValidation,
  PARAMETERS_BY_TYPE,
  filterByType,
};
