import { ReactElement, useMemo } from "react";
import PropTypes from "prop-types";
import Copy from "@mui/icons-material/FileCopyOutlined";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { Link } from "react-router-dom";
import { RaRecord, useCreatePath, useRecordContext, useResourceContext } from "ra-core";
import { Button, ButtonProps, useTranslate } from "react-admin";

/**
 * Opens the Edit view of a given record:
 *
 * @example // basic usage
 * import { EditButton } from 'react-admin';
 *
 * const CommentEditButton = ({ record }) => (
 *     <EditButton basePath="/comments" label="Edit comment" record={record} />
 * );
 */
const CopyButton = (props: EditButtonProps) => {
  const { icon = defaultIcon, label = "common.actions.copy", scrollToTop = true, ...rest } = props;
  const record = useRecordContext();
  const resource = useResourceContext();
  const translate = useTranslate();
  const createPath = useCreatePath();

  return (
    <Button
      aria-test={"configuration-copyBtn"}
      component={Link}
      to={useMemo(
        () => ({
          pathname: record ? createPath({ type: `/copy/${resource}/${record.id}` }) : "",
          state: { _scrollToTop: scrollToTop },
        }),
        [record, resource, scrollToTop, createPath]
      )}
      label={translate(label)}
      onClick={stopPropagation}
      {...(rest as any)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <Copy />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: { stopPropagation: () => any }) => e.stopPropagation();

interface Props {
  basePath?: string;
  icon?: ReactElement;
  label?: string;
  record?: RaRecord;
  scrollToTop?: boolean;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

CopyButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
  scrollToTop: PropTypes.bool,
};

export default CopyButton;
