import { Route } from "react-router-dom";
import Settings from "@/Settings/index";
import Overview from "@/Components/Models/Configuration/Overview";
import {
  EnableOpenWindowAction,
  EnableCopyWindowAction,
  EnableExploreActionsModels,
} from "@/Components/Models";
import Copy from "@/Components/Models/Configuration/Copy";
import Explore from "@/Components/Models/Connection/Explore";

const routesExplore = EnableExploreActionsModels.map((resource) => {
  return <Route key={`explore-${resource}`} path={`${resource}/explore`} element={<Explore />} />;
});
const routesOverview = EnableOpenWindowAction.map((resource) => {
  return (
    <Route key={`overview-${resource}`} path={`/${resource}/:id/overview`} element={<Overview />} />
  );
});

const routesCopy = EnableCopyWindowAction.map((resource) => {
  return <Route key={`copy-${resource}`} path={`/copy/${resource}/:id`} element={<Copy />} />;
});

const routes = [
  <Route key={`settings`} path="/settings" element={<Settings />} />,
  ...routesExplore,
  ...routesOverview,
  ...routesCopy,
];

export default routes;
