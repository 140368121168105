import { alpha, createTheme, getContrastRatio, Theme } from "@mui/material/styles";
import { defaultTheme } from "react-admin";

const isProd = process.env.REACT_APP_API_URL?.startsWith(
  "https://console.mft.data.arkemacloud.com/api"
);

declare module "@mui/material/styles" {
  interface Palette {
    custom: Palette["primary"];
  }

  interface PaletteOptions {
    custom?: PaletteOptions["primary"];
  }
}

export const darkTheme: Theme = createTheme({
  ...defaultTheme,
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9",
      light: alpha("#90caf9", 0.5),
      dark: alpha("#90caf9", 0.9),
      contrastText: getContrastRatio("#90caf9", "#fff") > 4.5 ? "#fff" : "#111",
    },
    secondary: {
      main: "#FBBA72",
      light: alpha("#FBBA72", 0.5),
      dark: alpha("#FBBA72", 0.9),
      contrastText: getContrastRatio("#FBBA72", "#fff") > 4.5 ? "#fff" : "#111",
    },
    background: {
      default: "#303030",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: isProd ? "#3c3f44" : "#313831",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#fff",
          backgroundColor: isProd ? "#4269ffe6" : "#00933ee6",
        },
        root: {
          border: "none",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #616161e6",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #ffffffb3",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&:hover:active::after": {
            content: '""',
            display: "block",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "currentColor",
            opacity: 0.3,
            borderRadius: "inherit",
          },
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  ...defaultTheme,
  palette: {
    mode: "light" as "light",
    primary: {
      main: "#4f3cc9",
      light: alpha("#4f3cc9", 0.5),
      dark: alpha("#4f3cc9", 0.9),
      contrastText: getContrastRatio("#4f3cc9", "#fff") > 4.5 ? "#fff" : "#111",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: isProd ? "#e9f0ff" : "#e9ffe9",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #fff",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #4f3cc9",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "none",
        },
        root: {
          border: "1px solid #e0e0e3",
          backgroundClip: "padding-box",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "#fff",
          backgroundColor: "#4f3cc9",
          boxShadow: "none",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          "&:hover:active::after": {
            // recreate a static ripple color
            // use the currentColor to make it work both for outlined and contained buttons
            // but to dim the background without dimming the text,
            // put another element on top with a limited opacity
            content: '""',
            display: "block",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "currentColor",
            opacity: 0.3,
            borderRadius: "inherit",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#fff",
          backgroundColor: isProd ? "#4269ffe6" : "#00933ee6",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#f5f5f5",
        },
        barColorPrimary: {
          backgroundColor: "#d7d7d7",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          "&$disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
  },
});
