import { createContext, ReactNode, useContext, useState } from "react";

type NextTokenState = string | null;
interface NextTokenContextInterface {
  nextToken: NextTokenState;
  setNextToken: (value: NextTokenState) => void;
}
type NextTokenProviderProps = {
  children: ReactNode;
};

const NextTokenContext = createContext<NextTokenContextInterface | null>(null);

export const NextTokenProvider = ({ children }: NextTokenProviderProps) => {
  const [token, setToken] = useState<NextTokenState>(null);

  const setNextToken = (value: NextTokenState): void => {
    setToken(value);
  };

  const value: NextTokenContextInterface = {
    nextToken: token,
    setNextToken: setNextToken,
  };

  return <NextTokenContext.Provider value={value}> {children} </NextTokenContext.Provider>;
};

export const useNextToken = (): NextTokenContextInterface => {
  const context = useContext(NextTokenContext);
  if (!context) {
    throw new Error("useNextToken must be used within a NextTokenProvider");
  }
  return context;
};
