import "./App.css";
import { Admin, Resource, useTranslate, CustomRoutes } from "react-admin";

import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";

import customRoutes from "@/routes";

import ApiProvider from "@/Providers/ApiProvider";
import { buildAuthProvider } from "@/Providers/AuthProvider";
import { CacheProvider } from "@/Providers/CacheProvider";
import { NextTokenProvider } from "@/Providers/NextTokenProvider";

import { Layout } from "@/Components/Layout";
import Dashboard from "@/Components/Dashboard";
import Models from "@/Components/Models";
import { darkTheme, lightTheme } from "@/Components/Layout/themes";

import { getAuthorizedPage, grantAccess } from "./Settings/roles";
import i18nProvider from "@/Settings/i18n";

const {
  REACT_APP_AWS_REGION,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_API_URL,
  REACT_APP_COGNITO_DOMAIN,
} = process.env;

const redirectUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

Amplify.configure(
  {
    Auth: {
      Cognito: {
        identityPoolId: REACT_APP_IDENTITY_POOL_ID as string,
        userPoolId: REACT_APP_USER_POOL_ID as string,
        userPoolClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID as string,
        allowGuestAccess: false,
        loginWith: {
          oauth: {
            domain: REACT_APP_COGNITO_DOMAIN!.replace("https://", ""),
            scopes: ["email", "profile", "openid"],
            redirectSignIn: [redirectUrl],
            redirectSignOut: [redirectUrl],
            responseType: "code",
          },
        },
      },
    },
    API: {
      REST: {
        api: {
          endpoint: REACT_APP_API_URL as string,
          region: REACT_APP_AWS_REGION,
        },
      },
    },
  },
  {
    API: {
      REST: {
        headers: async () => {
          return {
            Authorization: `Bearer ${(await fetchAuthSession()).tokens?.idToken?.toString()}`,
            ClientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };
        },
      },
    },
  }
);

const authProvider = buildAuthProvider();
const dataProvider = ApiProvider();

const App = () => {
  const translate = useTranslate();

  return (
    <CacheProvider>
      <NextTokenProvider>
        <Admin
          title={"MFT Admin"}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
          authProvider={authProvider}
          layout={Layout}
          dashboard={Dashboard}
          disableTelemetry={true}
          lightTheme={lightTheme}
          darkTheme={darkTheme}
          defaultTheme="light"
        >
          <CustomRoutes>{customRoutes}</CustomRoutes>
          {(permissions) =>
            Object.keys(Models).map((key) =>
              grantAccess(permissions, key) ? (
                <Resource
                  key={key}
                  name={key}
                  {...getAuthorizedPage(permissions, { ...Models[key] })}
                  options={{ label: translate(`resources.${key}.name`), roles: permissions }}
                />
              ) : null
            )
          }
        </Admin>
      </NextTokenProvider>
    </CacheProvider>
  );
};

export default App;
