import { createContext, ReactNode, useContext } from "react";
import { useStore } from "react-admin";

interface CacheState {
  [key: string]: {
    data: any;
    timestamp: number;
    expiresIn: number;
  };
}
interface CacheContextInterface {
  cache: CacheState;
  setCache: (key: string, data: any, expiresIn?: number) => void;
  getCache: (key: string) => any | null;
  resetCache: () => void;
  removeCachedItem: (key: string) => void;
  isCacheValid: (key: string) => boolean;
}
type CacheProviderProps = {
  children: ReactNode;
  cacheDuration?: number; //default cache validity time
};

const CacheContext = createContext<CacheContextInterface | null>(null);

export const CacheProvider = ({ children, cacheDuration = 5 * 60 * 1000 }: CacheProviderProps) => {
  const [cache, setStoreCache] = useStore<CacheState>("cache", {});

  const isCacheValid = (key: string): boolean => {
    const cachedItem = cache[key];
    if (!cachedItem) return false;
    return Date.now() < cachedItem.timestamp + cachedItem.expiresIn;
  };

  const setCache = (key: string, data: any, expiresIn: number = cacheDuration): void => {
    setStoreCache({
      ...cache,
      [key]: {
        data,
        timestamp: Date.now(),
        expiresIn,
      },
    });
  };

  const getCache = (key: string): any | null => {
    const cachedItem = cache[key];
    if (!cachedItem) return null;
    if (!isCacheValid(key)) {
      removeCachedItem(key);
      return null;
    }
  };

  const removeCachedItem = (key: string): void => {
    const newCache = structuredClone(cache);
    delete newCache[key];
    setStoreCache(newCache);
  };

  const resetCache = (): void => {
    setStoreCache({});
  };

  const value: CacheContextInterface = {
    cache: cache,
    setCache,
    getCache,
    resetCache,
    isCacheValid,
    removeCachedItem,
  };

  return <CacheContext.Provider value={value}> {children} </CacheContext.Provider>;
};

export const useCache = (): CacheContextInterface => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error("useCache must be used within a CacheProvider");
  }
  return context;
};
