import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TopToolbar } from "react-admin";

const classes = {
  searchForm: "mft-cstm-searchForm",
  filesContainer: "mft-cstm-filesContainer",
  filterSource: "mft-cstm-filterSource",
  sourceContainer: "mft-cstm-sourceContainer",
  formArea: "mft-cstm-formArea",
  searchBarActions: "mft-cstm-searchBarActions",
  toolbarActions: "mft-cstm-toolbarActions",
};

const StyledContainer = styled(Box)(({ theme }) => ({
  [`&.${classes.searchForm}`]: {
    margin: "12px 0 12px 0",
    display: "block",
    padding: "15px 15px 0 15px",
    backgroundColor: theme.palette.mode === "dark" ? "initial" : "#f4f4f4",
    borderRadius: "12px",
    border: theme.palette.mode === "dark" ? "1px solid #575757" : "1px solid #e9e9e9",
  },
  [`&.${classes.filesContainer}`]: {
    width: "80%",
    border: theme.palette.mode === "dark" ? "1px solid #575757" : "1px solid #e9e9e9",
    borderRadius: "6px",
    margin: "18px auto",
  },
  [`&.${classes.filterSource}`]: {
    border: theme.palette.mode === "dark" ? "1px solid #575757" : "1px solid #e9e9e9",
    padding: "24px 24px 0 25px;",
    marginBottom: "2rem",
    borderRadius: "13px",
    width: "auto",
  },
  [`&.${classes.sourceContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
  },
  [`&.${classes.formArea}`]: {
    border: theme.palette.mode === "dark" ? "1px solid #575757" : "1px solid #e9e9e9",
    padding: "24px 24px 0 25px;",
    borderRadius: "13px",
    width: "auto",
    marginBottom: "1rem",
  },
  [`&.${classes.searchBarActions}`]: {
    margin: "12px 0 12px 0",
    display: "block",
    backgroundColor: theme.palette.mode === "dark" ? "initial" : "#f4f4f4",
    borderRadius: "12px",
    textAlign: "center",
    border: theme.palette.mode === "dark" ? "1px solid #575757" : "1px solid #e9e9e9",
  },
}));

const StyledToolbar = styled(TopToolbar)(() => ({
  [`&.${classes.toolbarActions}`]: {
    justifyContent: "flex-start",
    transform: "unset",
  },
}));

export { StyledContainer, StyledToolbar, classes };
