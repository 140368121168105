import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslate } from "react-admin";

const cardClasses = {
  root: "mft-welcome-card-root",
  action: "mft-welcome-card-action",
};
const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${cardClasses.root}`]: {
    background:
      theme.palette.mode === "dark"
        ? "#535353"
        : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,

    color: "#fff",
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: "1em",
  },
}));

const Welcome = () => {
  const translate = useTranslate();
  return (
    <StyledCard className={cardClasses.root}>
      <Typography variant="h5" component="h2" gutterBottom>
        {translate("pos.dashboard.welcome.title")}
      </Typography>
      <Box maxWidth="40em">
        <Typography variant="body1" component="p" gutterBottom>
          {translate("pos.dashboard.welcome.subtitle")}
        </Typography>
      </Box>
    </StyledCard>
  );
};

export default Welcome;
