export const DEFAULT_TRANSFERT_TYPE = "DELETE_AFTER";
export const DEFAULT_TRANSFERT_MODE = "NOT_ORDERED";
export const DEFAULT_POLLING_TYPE = "S3_DEPOSIT";
export const DEFAULT_S3_DEPOSIT_CONNECTION = "S3_DEPOSIT";
export const INTEGRATION_TYPES = {
  WRITE_FILE: "WRITE_FILE",
  START_FLOW: "START_FLOW",
};
export const TRANSFERT_TYPES = {
  KEEP_IN_PLACE: "KEEP_IN_PLACE",
  MOVE_AFTER: "MOVE_AFTER",
  DELETE_AFTER: "DELETE_AFTER",
};
export const TRANSFERT_MODES = {
  ORDERED: "ORDERED",
  NOT_ORDERED: "NOT_ORDERED",
  CANCEL_REPLACE: "CR",
};
export const POLLING_TYPES = {
  AUTO: "AUTOMATIC",
  FLAG: "FLAG",
  DEPOSIT: "S3_DEPOSIT",
};
export const TRANSFORMATIONS = {
  ARCHIVE: "ARCHIVE",
  CSV_TO_JSON: "CSV_TO_JSON",
  GUNZIP: "GUNZIP",
  GZIP: "GZIP",
  UNARCHIVE: "UNARCHIVE",
  WIN1252_TO_UTF8: "WIN1252_TO_UTF8",
  XML_TO_JSON: "XML_TO_JSON",
};
export const FILENAME_PATTERNS = {
  CHRONO: "CHRONO",
  KEEP: "KEEP",
  NOW: "NOW",
  RENAME: "RENAME",
  RENAME_CHRONO: "RENAME_CHRONO",
  RENAME_NOW: "RENAME_NOW",
  TRIM: "TRIM",
};
