import { Fragment, ReactElement, ReactNode } from "react";
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSidebarState, useTranslate } from "react-admin";

const iconClasses = {
  icon: "mft-submenu-icon",
};
const listClasses = {
  sidebarIsOpen: "mft-sidebar-open",
  sidebarIsClosed: "mft-sidebar-close",
};

const StyledIcon = styled(ListItemIcon)(({ theme }) => ({
  icon: { minWidth: theme.spacing(5) },
}));
const StyledList = styled(List)(({ theme }) => ({
  [`${listClasses.sidebarIsOpen}`]: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      paddingLeft: theme.spacing(4),
    },
  },
  [`${listClasses.sidebarIsClosed}`]: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      paddingLeft: theme.spacing(2),
    },
  },
}));

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

const SubMenu = (props: Props) => {
  const { handleToggle, isOpen, name, icon, children, dense } = props;
  const translate = useTranslate();
  const [open] = useSidebarState();

  const header = (
    <MenuItem dense={dense} onClick={handleToggle}>
      {" "}
      {/*button*/}
      <StyledIcon className={iconClasses.icon}>{isOpen ? <ExpandMore /> : icon}</StyledIcon>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {open || isOpen ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <StyledList
          dense={dense}
          //   component="div"
          disablePadding
          className={open ? listClasses.sidebarIsOpen : listClasses.sidebarIsClosed}
        >
          {children}
        </StyledList>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
