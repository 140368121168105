import { clearStyleFromHtml } from "@/Tools/helpers";
import { RichTextInput } from "ra-input-rich-text";
import {
  Create,
  PasswordInput,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  SortPayload,
  TextInput,
} from "react-admin";
const sortable: SortPayload = { field: "name", order: "ASC" };
const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=])[A-Za-z\d!@#$%^&*()_\-+=]{10,}$/;
const CreateForm = (props: any) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="username" validate={[required()]} />
      <RichTextInput source="description" validate={[required()]} parse={clearStyleFromHtml} />
      <ReferenceInput
        source="auth_type"
        reference="authType"
        sort={sortable}
      >
        <SelectInput optionText="name" validate={[required()]}/>
      </ReferenceInput>
      <PasswordInput
        source="password"
        validate={[
          required(),
          regex(passwordRegex, "resources.incomingHttpCredentials.fields.passwordError"),
        ]}
      />
    </SimpleForm>
  </Create>
);

export default CreateForm;
