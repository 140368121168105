import Icon from "@mui/icons-material/TextFields";
import List from "./List";
import Show from "./Show";

const TransfertType = {
  list: List,
  show: Show,
  icon: Icon,
};

export default TransfertType;
