import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, styled } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { useTranslate } from "ra-core";
import { TextInput, InputProps } from "react-admin";

const searchClasses = {
  input: "mft-search-custom-input", //RaSearchInput ?
};
const StyledSearchInput = styled(TextInput)(() => ({
  [`& .${searchClasses.input}`]: {
    input: {
      marginTop: 32,
    },
  },
}));
const SearchInput = (props: SearchInputProps) => {
  const { classes: classesOverride, label, ...rest } = props;
  const translate = useTranslate();

  return (
    <StyledSearchInput
      label={label as string}
      resettable
      placeholder={translate("ra.action.search")}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      className={searchClasses.input}
      {...rest}
    />
  );
};

SearchInput.propTypes = {
  classes: PropTypes.object,
};

export type SearchInputProps = InputProps<TextFieldProps> & Omit<TextFieldProps, "helperText">;

export default SearchInput;
