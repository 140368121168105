import {
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField,
  usePermissions,
  Loading,
  useResourceContext,
} from "react-admin";
import { ShowActionsTopToolbar } from "..";

const ShowForm = (props: any) => {
  const resource = useResourceContext();
  const { isLoading, permissions } = usePermissions();
  return isLoading ? (
    <Loading />
  ) : (
    <Show actions={<ShowActionsTopToolbar roles={permissions} {...props} />}>
      <SimpleShowLayout>
        <TextField source="name" />
        {resource !== "user" && <RichTextField source="description" />}
        {props.children}
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowForm;
