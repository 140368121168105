import {
  CheckboxGroupInput,
  useGetList,
  useRecordContext,
} from "react-admin";

import { RoleObject } from "@/Settings/roles";

const RoleInput = (props: any) => {
  const record = useRecordContext();
  const removeEmpty = (inputs: (string | RoleObject)[]) => {
    return inputs.filter((input) => input !== undefined);
  };
  const { data: userRolesTypes, isLoading } = useGetList("userRolesType");

  const choices = userRolesTypes?.map((item: RoleObject) => {
    return { id: item.id, name: item.name };
  });

  return (
    <>
      <CheckboxGroupInput
        parse={removeEmpty}
        source={props.source}
        label={"resources.user.fields.updateRoles"}
        choices={choices}
        optionValue="id"
        optionText="name"
        defaultValue={record?.roles}
        isLoading={isLoading}
      />
    </>
  );
};

export default RoleInput;
