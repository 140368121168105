import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { cleanText, getUsers } from "@/Tools/exporterTools";
import { dropDuplicates, removeEmptyOrNullString } from "@/Tools/helpers";

const BaseExporter = async (records: any) => {
  const resource = window.location.hash.split("/")[1].split("?")[0];
  const userIds = dropDuplicates(
    removeEmptyOrNullString([
      ...records.map((record: any) => record.created_by),
      ...records.map((record: any) => record.updated_by),
    ])
  );
  const users = await getUsers(userIds);
  const data = records.map((record: any) => {
    return {
      ...record,
      ...(record.description && { description: cleanText(record.description) }),
      created_by: users[record.created_by] ?? record.created_by,
      updated_by: users[record.updated_by] ?? record.updated_by,
    };
  });

  return jsonExport(data, { rowDelimiter: "," }, (error: any, csv: any) => {
    if (error) console.log("Error CSV Export : ", error);
    downloadCSV(csv, `${resource}_export_${Date.now()}`);
  });
};

export default BaseExporter;
