import {
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  List,
  useTranslate,
  Datagrid,
  EditButton,
  ShowButton,
  useResourceContext,
  usePermissions,
  Loading,
} from "react-admin";
import { useEditNeedActions } from "..";
import { authorizeAction } from "@/Settings/roles";

const ListForm = (props: any) => {
  const resource = useResourceContext()!;
  const { permissions, isLoading } = usePermissions();
  const translate = useTranslate();
  const editNeedActions =
    useEditNeedActions(resource) &&
    authorizeAction(permissions, resource, "edit");

    const filters = [
    <ReferenceInput
      source="source.auth_type"
      reference="authType"
      label={translate("resources.incomingHttpCredentials.fields.authType")}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
  ];

  return isLoading ? <Loading/> : (
    <List {...props} filters={filters} pagination={false} >
      <Datagrid {...props}>
        <TextField source="username" />
        <ReferenceField source="auth_type" reference="authType" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="created_by" reference="user" link="show">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" showTime={true} />
        <ReferenceField source="updated_by" reference="user" link="show">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="updated_at" showTime={true} />
        <ShowButton />
        {editNeedActions && <EditButton />}
      </Datagrid>
    </List>
  );
};

export default ListForm;
