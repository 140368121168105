import Icon from "@mui/icons-material/Cached";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import Show from "./Show";

const Polling = {
  list: List,
  show: Show,
  create: Create,
  edit: Edit,
  icon: Icon,
};

export default Polling;
