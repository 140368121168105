import { EmailField, Labeled } from "react-admin";
import BaseModel from "../BaseModel/Show";
import { UserTitle } from ".";
import SimpleChipField from "./SimpleChipField";

const ShowForm = (props: any) => {
  return (
    <BaseModel {...props} title={<UserTitle />}>
      <EmailField source="email" />
      <Labeled label="resources.user.fields.roles">
        <SimpleChipField />
      </Labeled>
    </BaseModel>
  );
};

export default ShowForm;
