import { DEFAULT_S3_DEPOSIT_CONNECTION } from "@/Components/Models/Configuration/constants";
import { useEffect, useMemo, useRef } from "react";
import { useGetList, useRedirect, useRefresh } from "react-admin";
import { useLocation } from "react-router-dom";

export function useSearchParams() {
  const { search } = useLocation();
  return useMemo(() => {
    const x = new URLSearchParams(search);
    return x;
  }, [search]);
}

export function useRedirectWithParams(resource: string, params: Array<Array<string>>) {
  const redirect = useRedirect();
  const refresh = useRefresh();
  return () => {
    const urlParams = new URLSearchParams(params);
    redirect(`/${resource}?${urlParams.toString()}`);
    refresh();
  };
}

export const useS3DepositConnection = () => {
  const s3DepositConnection = useRef("");
  const { data: connections } = useGetList("connection", {
    filter: { name: DEFAULT_S3_DEPOSIT_CONNECTION },
    pagination: { page: 1, perPage: 1 },
  });

  useEffect(() => {
    if (connections && connections.length > 0) {
      s3DepositConnection.current = connections[0].id;
    }
  }, [connections]);

  return s3DepositConnection.current;
};
