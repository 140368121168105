import {
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  Toolbar,
} from "react-admin";
import { ConnectionTitle } from ".";
import EditBase from "../BaseModel/Edit";
import MaintenanceArea from "./Maintenance";
import { ParametersInputs, ParametersValidation, filterByType } from "./Parameters";
import Retry from "./Retry";

const UpdateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      type="button"
      transform={(data) => {
        const { id, created_at, created_by, updated_at, updated_by, parameters, ...rest } = data;
        return {
          ...rest,
          parameters: filterByType(rest.type, parameters),
        };
      }}
    />
  </Toolbar>
);

const EditForm = (props: any) => (
  <EditBase
    {...props}
    toolbar={<UpdateToolbar />}
    title={<ConnectionTitle />}
    validate={ParametersValidation}
  >
    <BooleanInput source="enabled" defaultValue={true} />
    <ReferenceInput source="type" reference="connectionType" >
      <SelectInput optionText="name" validate={[required()]} />
    </ReferenceInput>
    <FormDataConsumer>
      {({formData}) => {
        return <ParametersInputs parameterType={formData.type} source="parameters" validate={[required()]} />
      }}
    </FormDataConsumer>
    <MaintenanceArea />
    <Retry />
  </EditBase>
);

export default EditForm;
