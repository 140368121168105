import {
  Edit,
  PasswordInput,
  ReferenceInput,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SortPayload,
  TextInput,
  Toolbar,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { clearStyleFromHtml } from "@/Tools/helpers";
const sortable: SortPayload = { field: "name", order: "ASC" };
const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=])[A-Za-z\d!@#$%^&*()_\-+=]{10,}$/;

const UpdateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      type="button"
      transform={(data) => {
        const { id, name, created_at, created_by, updated_at, updated_by, ...rest } = data;
        return rest;
      }}
    />
  </Toolbar>
);

const cleanFields = (data: any) => {
  const { id, created_at, created_by, updated_at, updated_by, password, ...rest } = data;
  if (!password.length) return rest;
  return {
    password: password,
    ...rest,
  };
};

const EditForm = (props: any) => (
  <Edit {...props} toolbar={<UpdateToolbar />} transform={cleanFields}>
    <SimpleForm>
      <TextInput source="username" validate={[required()]} />
      <RichTextInput source="description" validate={[required()]} parse={clearStyleFromHtml} />
      <ReferenceInput
        source="auth_type"
        reference="authType"
        sort={sortable}
      >
        <SelectInput optionText="name" validate={[required()]}/>
      </ReferenceInput>
      <PasswordInput
        source="password"
        validate={[regex(passwordRegex, "resources.incomingHttpCredentials.fields.passwordError")]}
      />
    </SimpleForm>
  </Edit>
);

export default EditForm;
