import { TextField } from "react-admin";
import ShowBase from "../BaseModel/Show";

const ShowForm = (props: any) => (
  <ShowBase {...props}>
    <TextField source="command" />
    <TextField source="default_parameters" />
    <TextField source="default_path" />
  </ShowBase>
);

export default ShowForm;
