import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Loading, useGetOne, useTranslate, useTheme } from "react-admin";
import strip from "@/Tools/stripHtml";
import { useLocation, useParams } from "react-router-dom";

const Overview = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const translate = useTranslate();
  const [theme] = useTheme();

  const color = theme === "light" ? "#4f3cc9" : "#90caf9";
  const resource = pathname.split("/")[1] || "";
  const { data, isLoading, error } = useGetOne(`${resource}/details`, { id: id });
  if (isLoading) return <Loading />;
  if (error) throw error;
  if (!data) return null;

  return (
    <TableContainer
      component={Paper}
      style={{ maxWidth: 1200, marginTop: 20, marginBottom: 5, marginLeft: 10 }}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Summary</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Source</TableCell>
            {data.targets.map((target: any, index: number) => (
              <TableCell style={{ fontWeight: "bold" }}>Target {index + 1}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.name")} : {data.name}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {translate("resources.configuration.fields.code")} : {data.code}
            </TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.use_s3_deposit")} :{" "}
              {strip(data.source.use_s3_deposit)}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {translate("resources.configuration.fields.description")} : {strip(data.description)}
            </TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.description")} :{" "}
              {strip(data.source.description)}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.description")} :{" "}
                {strip(target?.description)}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              {translate("resources.configuration.fields.requestor")} : {data.requestor}
            </TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.connection")} :{" "}
              {data?.source.connection}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.connection")} :{" "}
                {target?.connection}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.demand_number")} : {data.demand_number}{" "}
            </TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.wisp_group")} :{" "}
              {data.source.wisp_group}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.wisp_group")}:{" "}
                {target?.wisp_group}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.criticity")} :{" "}
              <span style={{ color: color }}> {data.criticity} </span>{" "}
            </TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.application")} :{" "}
              {data.source.application}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.application")}:{" "}
                {target?.application}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              {translate("resources.configuration.fields.source.enabled")} :{" "}
              <span style={{ color: color }}>
                {" "}
                {data.enabled === true
                  ? translate("common.actions.yes")
                  : translate("common.actions.no")}{" "}
              </span>
            </TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.enabled")} :{" "}
              <span style={{ color: color }}>
                {" "}
                {data.source.enabled === true
                  ? translate("common.actions.yes")
                  : translate("common.actions.no")}{" "}
              </span>{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.enabled")} :{" "}
                <span style={{ color: color }}>
                  {" "}
                  {target.enabled === true
                    ? translate("common.actions.yes")
                    : translate("common.actions.no")}{" "}
                </span>{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              {translate("resources.configuration.fields.direct")} :{" "}
              <span style={{ color: color }}>
                {" "}
                {data.direct === true
                  ? translate("common.actions.yes")
                  : translate("common.actions.no")}{" "}
              </span>
            </TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.path")} : {data.source.path}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.path")}: {target?.path}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.job")} : {data.source.job}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.source.job")} : {target.job}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.information")} :{" "}
              {data.source.information}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.information")} :{" "}
                {target.information}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.regex")} : {data.source.regex}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {translate("resources.configuration.fields.target.pattern_file_name")} :{" "}
                <span style={{ color: color }}> {target.pattern_file_name}</span>{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.transfert_type")} :{" "}
              {data.source.transfert_type}{" "}
            </TableCell>
            {data.targets.map((target: any) => {
              return target.new_name &&
                (target.pattern_file_name === "RENAME" ||
                  target.pattern_file_name === "RENAME_CHRONO" ||
                  target.pattern_file_name === "RENAME_NOW") ? (
                <TableCell>
                  {" "}
                  {translate("resources.configuration.fields.target.new_name")} : {target.new_name}{" "}
                </TableCell>
              ) : (
                <TableCell>-</TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell />
            {data.source.transfert_type === "MOVE_AFTER" ? (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.source.transfert_path")} :{" "}
                {data.source.transfert_path}{" "}
              </TableCell>
            ) : (
              <TableCell>-</TableCell>
            )}
            {data.targets.map((target: any) => {
              return target.trim_character && target.pattern_file_name === "TRIM" ? (
                <TableCell>
                  {" "}
                  {translate("resources.configuration.fields.target.trim_character")} :{" "}
                  {target.trim_character}{" "}
                </TableCell>
              ) : (
                <TableCell>-</TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.transfert_mode")} :{" "}
              {data.source.transfert_mode}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>-</TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.nb_files_per_transfert")} :{" "}
              {data.source.nb_files_per_transfert}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.nb_files_per_transfert")} :{" "}
                {target.nb_files_per_transfert}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.delay")}:{" "}
                {target.delay?.delay_at_put ?? "0"}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.pollingType.name")} : {data.source.polling}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.integration")}:{" "}
                {JSON.stringify(target.integration, null, 4)}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.source.schedule_expression")}:{" "}
              {data.source.schedule_expression}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.source.schedule_expression")}:{" "}
                {target.schedule_expression}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {" "}
              {translate("resources.configuration.fields.transformation")} :{" "}
              {data.source.transformations}{" "}
            </TableCell>
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.transformation")} :{" "}
                {target.transformations?.toString()}{" "}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell />
            {data.targets.map((target: any) => (
              <TableCell>
                {" "}
                {translate("resources.configuration.fields.target.autoDisabled")} :
                <span style={{ color: color }}>
                  {" "}
                  {target.auto_disabled === true
                    ? translate("common.actions.yes")
                    : translate("common.actions.no")}{" "}
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default Overview;
