import { ComponentType, Fragment } from "react";
import {
  TopToolbar,
  RefreshButton,
  EditButton,
  DeleteButton,
  ListButton,
  BulkDeleteButton,
  BulkExportButton,
  useRecordContext,
  useResourceContext,
  useListContext,
} from "react-admin";
import { Box } from "@mui/material";

import authType from "@/Components/Models/AuthType";
import userRolesType from "@/Components/Models/UserRolesType";
import connectionType from "@/Components/Models/ConnectionType";
import transfertType from "@/Components/Models/TransfertType";
import transfertMode from "@/Components/Models/TransfertMode";
import criticityType from "@/Components/Models/CriticityType";
import pollingType from "@/Components/Models/PollingType";
import fileNamePattern from "@/Components/Models/FileNamePattern";
import transformationType from "@/Components/Models/TransformationType";
import integrationType from "@/Components/Models/IntegrationType";
import connection from "@/Components/Models/Connection";
import user from "@/Components/Models/User";
import incomingHttpCredentials from "@/Components/Models/IncomingHttpCredentials";
import tracking from "@/Components/Models/Tracking";
import polling from "@/Components/Models/Polling";
import integrationConfiguration from "@/Components/Models/IntegrationConfiguration";
import configuration from "@/Components/Models/Configuration";
import softlock from "@/Components/Models/Softlock";
import application from "@/Components/Models/Application";
import CustomEnableButton from "../CustomBulkActions/BulkEnableAction";
import { isValidExpression, isValidCron, isValidRate } from "@/Tools/parseSchedule";
import TestButton from "../CustomTestAction/TestAction";
import OpenWindowButton from "@/Components/CustomOpenWindowAction/OpenWindowAction";
import BulkDeleteWithChecking from "../CustomBulkActions/BulkDeleteWithChecking";
import CopyButton from "@/Components/Models/Configuration/CopyButton";
import { authorizeAction } from "@/Settings/roles";
import BaseExporter from "@/Components/Models/BaseModel/BaseExporter";
import ConfigurationExporter from "@/Components/Models/Configuration/Exporter";
import PollingExporter from "@/Components/Models/Polling/Exporter";
import IntegrationExporter from "@/Components/Models/IntegrationConfiguration/Exporter";

const scheduleValidation = (value: string) => {
  if (!value || !isValidExpression(value)) {
    return "The schedule expression is not valid (Start with rate or cron)";
  }
  if (!isValidCron(value) && !isValidRate(value)) {
    return "The schedule expression is not valid (bad rate or cron)";
  }
  return undefined;
};

const checkTransformationsOrder = (value: string[]) => {
  if (value === undefined || !value.length) {
    return undefined;
  }
  if (value.includes("ARCHIVE") && value.length > 1) {
    return "ARCHIVE Transformation must be the only transformation. You cannot apply the other transformations before or after the archive.";
  }
  if (value.includes("UNARCHIVE") && value.indexOf("UNARCHIVE") !== 0) {
    return "UNARCHIVE Transformation can only be the first transformation. You have to apply other transformations after the unarchive.";
  }
  if (value.filter((item, index) => value.indexOf(item) !== index).length) {
    return "You cannot chain the same transformation multiple times.";
  }
  return undefined;
};

const generateValidateArn = (regex: RegExp) => {
  return (value: string) => {
    if (!value) {
      return undefined;
    }
    if (!regex.test(value)) {
      return `Invalid ARN (Expected format: ${regex})`;
    }
    return undefined;
  };
};

const EditActionsModels: string[] = [
  "integrationConfiguration",
  "configuration",
  "polling",
  "connection",
  "application",
  "user",
  "incomingHttpCredentials",
];

const DeleteActionsModels: string[] = [
  "integrationConfiguration",
  "configuration",
  "polling",
  "softlock",
];

const DisableSearchFilters: string[] = ["configuration", "user", "tracking"];

const EnableActionsModels: string[] = ["connection", "configuration", "polling"];
const EnableExploreActionsModels: string[] = ["connection"];
const EnableTestActionsModels: string[] = ["connection"];
const EnableOpenWindowAction: string[] = ["configuration"];
const EnableCopyWindowAction: string[] = ["configuration"];
const DeleteWithCheckingAction: string[] = ["connection", "application"];

const useSearchFilters = (resource: string): boolean => !DisableSearchFilters.includes(resource);
const useExploreActions = (resource: string): boolean =>
  EnableExploreActionsModels.includes(resource);
const useEditNeedActions = (resource: string): boolean => EditActionsModels.includes(resource);
const useDeleteNeedActions = (resource: string): boolean => DeleteActionsModels.includes(resource);
const useNeedEnableActions = (resource: string): boolean => EnableActionsModels.includes(resource);
const useNeedTestActions = (resource: string): boolean =>
  EnableTestActionsModels.includes(resource);
const useNeedDeleteWithCheckingActions = (resource: string): boolean =>
  DeleteWithCheckingAction.includes(resource);

const useNeedOpenWindowActions = (resource: string): boolean =>
  EnableOpenWindowAction.includes(resource);

const useNeedCopyWindowActions = (resource: string): boolean =>
  EnableCopyWindowAction.includes(resource);

const ShowActionsTopToolbar = ({ roles }: any) => {
  const record = useRecordContext();
  const resource = useResourceContext()!;

  const editNeedActions = useEditNeedActions(resource) && authorizeAction(roles, resource, "edit");
  const copyNeedActions =
    useNeedCopyWindowActions(resource) && authorizeAction(roles, resource, "copy");
  const deleteNeedActions =
    useDeleteNeedActions(resource) && authorizeAction(roles, resource, "delete");
  const needTest = useNeedTestActions(resource) && authorizeAction(roles, resource, "test");
  const needOpenWindow = useNeedOpenWindowActions(resource);

  return (
    <TopToolbar>
      <ListButton />
      <RefreshButton />
      {needTest && <TestButton record={record} resource={resource} />}
      {needOpenWindow && <OpenWindowButton record={record} resource={resource} />}
      {editNeedActions && (
        <>
          <EditButton record={record} />
        </>
      )}
      {deleteNeedActions && (
        <>
          <DeleteButton record={record} resource={resource} />
        </>
      )}
      {copyNeedActions && (
        <>
          <CopyButton record={record} />
        </>
      )}
    </TopToolbar>
  );
};

function getCustomExporter(resource: string) {
  if (resource === "configuration") {
    return ConfigurationExporter;
  }
  if (resource === "polling") {
    return PollingExporter;
  }
  if (resource === "integrationConfiguration") {
    return IntegrationExporter;
  }
  return BaseExporter;
}

const ListBulkActionButtons = ({ roles, resource }: any) => {
  const { selectedIds } = useListContext();
  const deleteNeedActions =
    useDeleteNeedActions(resource) && authorizeAction(roles, resource, "delete");
  const needEnableActions =
    useNeedEnableActions(resource) && authorizeAction(roles, resource, "enable");
  const needDeleteWithVerificationActions =
    useNeedDeleteWithCheckingActions(resource) &&
    authorizeAction(roles, resource, "deleteWithCheck");

  const CustomExporter = getCustomExporter(resource);
  return (
    <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
      {needEnableActions && <CustomEnableButton selectedIds={selectedIds} resource={resource} />}
      {needDeleteWithVerificationActions && (
        <BulkDeleteWithChecking selectedIds={selectedIds} resource={resource} />
      )}
      {deleteNeedActions && <BulkDeleteButton aria-test={"delete-btn"} />}
      <BulkExportButton exporter={CustomExporter} />
    </Box>
  );
};

export type Model = {
  list: (props: any) => JSX.Element;
  show: (props: any) => JSX.Element;
  create?: (props: any) => JSX.Element;
  edit?: (props: any) => JSX.Element;
  copy?: (props: any) => JSX.Element;
  monitor?: (props: any) => JSX.Element;
  icon: ComponentType<any>;
};

export type ListModels = { [key: string]: Model };

const RootMenuModels: ListModels = {
  application,
  configuration,
  softlock,
  polling,
  connection,
  incomingHttpCredentials,
  integrationConfiguration,
  user,
  tracking,
};

const ConfigMenuModels: ListModels = {
  authType,
  connectionType,
  transfertType,
  transfertMode,
  criticityType,
  pollingType,
  fileNamePattern,
  transformationType,
  integrationType,
  userRolesType,
};

const AllModels: ListModels = Object.assign({}, RootMenuModels, ConfigMenuModels);

export default AllModels;
export {
  RootMenuModels,
  ConfigMenuModels,
  EditActionsModels,
  ShowActionsTopToolbar,
  ListBulkActionButtons,
  useSearchFilters,
  useEditNeedActions,
  useDeleteNeedActions,
  EnableExploreActionsModels,
  useExploreActions,
  EnableCopyWindowAction,
  useNeedCopyWindowActions,
  scheduleValidation,
  checkTransformationsOrder,
  generateValidateArn,
  EnableOpenWindowAction,
};
