import {
  useTranslate,
  TextInput,
  required,
  TextField,
  Labeled,
  NumberInput,
  NumberField,
} from "react-admin";
import { generateValidateArn } from "../..";

const PARAMETERS = ["bucketName", "roleARN", "roleSessionDurationInSec", "region"];

type S3Type = { customSx: Record<string, any> };

const Inputs = ({ customSx }: S3Type) => {
  const translate = useTranslate();

  const validRoleArn = generateValidateArn(/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9_-]{1,64}$/);
  return (
    <>
      <TextInput
        sx={customSx}
        source="parameters.bucketName"
        label={translate("resources.connection.fields.bucketName")}
        validate={[required()]}
      />
      <TextInput
        sx={customSx}
        source="parameters.region"
        label={translate("resources.connection.fields.region")}
        validate={[required()]}
      />
      <TextInput
        sx={customSx}
        source="parameters.roleARN"
        label={translate("resources.connection.fields.roleARN")}
        validate={[validRoleArn]}
      />
      <NumberInput
        sx={customSx}
        source="parameters.roleSessionDurationInSec"
        label={translate("resources.connection.fields.roleSessionDurationInSec")}
      />
    </>
  );
};

const Fields = ({ customSx }: S3Type) => {
  const translate = useTranslate();

  return (
    <>
      <Labeled
        source="parameters.bucketName"
        label={translate("resources.connection.fields.bucketName")}
        sx={customSx}
      >
        <TextField source="parameters.bucketName" />
      </Labeled>
      <Labeled
        source="parameters.region"
        label={translate("resources.connection.fields.region")}
        sx={customSx}
      >
        <TextField source="parameters.region" />
      </Labeled>
      <Labeled
        source="parameters.roleARN"
        label={translate("resources.connection.fields.roleARN")}
        sx={customSx}
      >
        <TextField source="parameters.roleARN" />
      </Labeled>
      <Labeled
        source="parameters.roleSessionDurationInSec"
        label={translate("resources.connection.fields.roleSessionDurationInSec")}
        sx={customSx}
      >
        <NumberField source="parameters.roleSessionDurationInSec" />
      </Labeled>
    </>
  );
};

export { Inputs, Fields, PARAMETERS };
