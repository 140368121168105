import {
  ArrayInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  SortPayload,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { clearStyleFromHtml } from "@/Tools/helpers";
import { checkTransformationsOrder } from "..";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";

type S3DepositSourceProps = {
  s3DepositConnection: string;
};

export function S3DepositSource({ s3DepositConnection }: S3DepositSourceProps) {
  const translate = useTranslate();
  const form = useForm();
  const sortable: SortPayload = { field: "name", order: "ASC" };

  useEffect(() => {
    form.setValue("source.connection", s3DepositConnection);
  }, [form, s3DepositConnection]);

  return (
    <StyledContainer className={classes.sourceContainer}>
      <span aria-test="configuration-create-source-description">
        <RichTextInput
          label={translate("resources.configuration.fields.source.description")}
          source="source.description"
          validate={[required()]}
          parse={clearStyleFromHtml}
        />
      </span>
      <ReferenceInput
        label={translate("resources.configuration.fields.source.connection")}
        source="source.connection"
        reference="connection"
        defaultValue={s3DepositConnection}
      >
        <SelectInput
          aria-test="configuration-create-source-connection"
          optionText="name"
          value={s3DepositConnection}
          readOnly
        />
      </ReferenceInput>
      <BooleanInput
        aria-test="configuration-create-source-enabled"
        label={translate("resources.configuration.fields.source.enabled")}
        source="source.enabled"
        defaultValue={true}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.application")}
        source="source.application"
        reference="application"
        sort={sortable}
      >
        <SelectInput
          aria-test="configuration-create-source-application"
          optionText="name"
          validate={[required()]}
        />
      </ReferenceInput>
      <TextInput
        aria-test="configuration-create-source-job"
        label={translate("resources.configuration.fields.source.job")}
        source="source.job"
        parse={(value: any) => value}
      />
      <TextInput
        aria-test="configuration-create-source-information"
        label={translate("resources.configuration.fields.source.information")}
        source="source.information"
        parse={(value: any) => value}
      />
      <ArrayInput
        label={translate("resources.configuration.fields.source.transformations")}
        source="source.transformations"
        validate={[checkTransformationsOrder]}
      >
        <SimpleFormIterator>
          <ReferenceInput
            source=""
            reference="transformationType"
            label="resources.configuration.fields.transformation"
          >
            <SelectInput
              aria-test="configuration-create-source-transformation"
              optionText="name"
              validate={[required()]}
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </StyledContainer>
  );
}
