import { get } from "@aws-amplify/api";
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { Loading, SortPayload, Title, useNotify, useTranslate } from "react-admin";
import { Form, FormProvider, useForm } from "react-hook-form";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";
import {
  StyledReferenceInput,
  classes as InputClasses,
  StyledButton,
  StyledTextInput,
  StyledSelectInput,
} from "@/Components/CustomStyled/Input";
import classNames from "classnames";

function Explore() {
  const [loading, setLoading] = useState(false);
  const [defaultConnection, setDefaultConnection] = useState();
  const [defaultPath, setDefaultPath] = useState("");
  const [files, setFiles] = useState<Record<string, any>>([]);
  const translate = useTranslate();
  const notify = useNotify();

  const exploreDirectory = async (values: any) => {
    const { connection, path } = values.data;
    setLoading(true);
    setDefaultConnection(connection);
    setDefaultPath(path);
    get({
      apiName: "api",
      path: `/connection/explore/${connection}`,
      options: {
        queryParams: {
          path: path,
        },
      },
    })
      .response.then(({ body }) => body.json() as Record<string, any>)
      .then((data) => setFiles(data))
      .catch((error: any) => {
        setFiles([]);
        if (error.response && error.response.body) {
          const { message, detail } = JSON.parse(error.response.body);
          notify(`${message}: ${detail}`, { type: "error" });
        } else {
          notify(error.message, { type: "error" });
        }
      })
      .finally(() => setLoading(false));
  };

  const SearchBar = () => {
    const methods = useForm();
    const translate = useTranslate();
    const sortable: SortPayload = { field: "name", order: "ASC" };
    return (
      <StyledContainer className={classes.searchForm}>
        <FormProvider {...methods}>
          <Form onSubmit={exploreDirectory}>
            <StyledReferenceInput
              source="connection"
              reference="connection"
              className={InputClasses.searchFormReference}
              sort={sortable}
              defaultValue={defaultConnection}
            >
              <StyledSelectInput
                optionText="name"
                defaultValue={defaultConnection}
                className={classNames(
                  InputClasses.searchFormSelect,
                  InputClasses.searchFormExplorerSelect
                )}
              />
            </StyledReferenceInput>
            <StyledTextInput
              source="path"
              className={InputClasses.searchFormText}
              defaultValue={defaultPath}
              sx={{ minWidth: "20rem" }}
            />
            <StyledButton
              className={InputClasses.searchFormSubmit}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              label={translate("resources.tracking.searchForm.submitButton")}
            />
          </Form>
        </FormProvider>
      </StyledContainer>
    );
  };

  return (
    <div>
      <Title title={"Explore Connections"} />
      <SearchBar />
      <TableContainer>
        <TableHead>
          <TableRow>
            <TableCell>{translate("common.actions.explore.name")}</TableCell>
            <TableCell>{translate("common.actions.explore.type")}</TableCell>
            <TableCell>{translate("common.actions.explore.size")}</TableCell>
            <TableCell>{translate("common.actions.explore.modifiedAt")}</TableCell>
          </TableRow>
        </TableHead>
        {loading ? (
          <Loading />
        ) : (
          files.map((file: any) => {
            return (
              <TableBody>
                <TableRow>
                  <TableCell>{file.name}</TableCell>
                  <TableCell>{file.type}</TableCell>
                  <TableCell>{file.size}</TableCell>
                  <TableCell>{file.modified_at}</TableCell>
                </TableRow>
              </TableBody>
            );
          })
        )}
      </TableContainer>
    </div>
  );
}

export default Explore;
