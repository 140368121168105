import Icon from "@mui/icons-material/InsertLink";
import List from "./List";
import Edit from "./Edit";
import Create from "./Create";
import Show from "./Show";
import { useRecordContext } from "react-admin";

const ConnectionTitle = () => {
  const record = useRecordContext();
  return <span>Connection {record ? `${record.name}` : ""}</span>;
};

const Connection = {
  list: List,
  show: Show,
  create: Create,
  edit: Edit,
  icon: Icon,
};

export default Connection;
export { ConnectionTitle };
