import { CheckboxGroupInput, useGetList } from "react-admin";

import { RoleObject } from "@/Settings/roles";

const RoleAppInput = (props: any) => {
  const removeEmpty = (inputs: (string | RoleObject)[]) => {
    return inputs.filter((input) => input !== undefined);
  };

  const { data: applications, isLoading } = useGetList("application");

  const choices = applications?.map((item: RoleObject) => {
    return { id: item.id, name: item.name };
  });

  return (
    <>
      <CheckboxGroupInput
        parse={removeEmpty}
        source={props.source}
        label={"Apps"}
        choices={choices}
        optionValue="id"
        optionText="name"
        isLoading={isLoading}
      />
    </>
  );
};

export default RoleAppInput;
