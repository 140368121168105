import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import {
  Button,
  DateTimeInput,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";

const classes = {
  searchFormSubmit: "mft-cstm-searchFormSubmit",
  searchFormText: "mft-cstm-searchFormText",
  searchFormNullableBoolean: "mft-cstm-searchFormNullableBoolean",
  searchFormSelect: "mft-cstm-searchFormSelect",
  searchFormExplorerSelect: "mft-cstm-searchFormExplorerSelect",
  searchFormReference: "mft-cstm-searchFormReference",
  searchFormDatePicker: "mft-cstm-searchFormDatePicker",
};

const StyledButton = styled(Button)<{ children?: ReactNode }>(() => ({
  [`&.${classes.searchFormSubmit}`]: {
    margin: "0px 10px 0 0",
    minWidth: "215px",
    padding: "11px",
    borderRadius: "9px",
  },
}));

const StyledTextInput = styled(TextInput)(() => ({
  [`&.${classes.searchFormText}`]: {
    margin: "0px 10px 0 0",
    maxWidth: "215px",
  },
}));

const StyledReferenceInput = styled(ReferenceInput)(() => ({
  [`&.${classes.searchFormReference}`]: {
    margin: "0px 10px 0 0",
    maxWidth: "215px",
  },
}));

const StyledSelectInput = styled(SelectInput)(() => ({
  [`&.${classes.searchFormSelect}`]: {
    margin: "0px 10px 0 0",
    maxWidth: "215px",
  },
  [`&.${classes.searchFormExplorerSelect}`]: {
    maxWidth: "unset",
  },
}));

const StyledNullableBooleanInput = styled(NullableBooleanInput)(() => ({
  [`&.${classes.searchFormNullableBoolean}`]: {
    margin: "0px 10px 0 0",
    maxWidth: "215px",
  },
}));

const StyledDateTimeInput = styled(DateTimeInput)(() => ({
  [`&.${classes.searchFormDatePicker}`]: {
    margin: "0px 10px 0 0",
    minWidth: "215px",
  },
}));

export {
  StyledButton,
  StyledTextInput,
  StyledReferenceInput,
  StyledSelectInput,
  StyledNullableBooleanInput,
  StyledDateTimeInput,
  classes,
};
