import BaseModel from "../BaseModel/Show";
import { TextField } from "react-admin";

const ShowForm = (props: any) => (
  <BaseModel {...props}>
    <TextField source="parameters" />
  </BaseModel>
);

export default ShowForm;
