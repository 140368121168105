import { Button, ButtonProps, RaRecord } from "react-admin";
import DownloadIcon from "@mui/icons-material/Download";
import { ReactElement } from "react";
import { get } from "aws-amplify/api";

interface Props {
  icon?: ReactElement;
  record?: RaRecord;
}

type DownloadFileActionProps = Props & ButtonProps;

const triggerDownload = (fileName: string, url: string) => {
  let link = document.createElement("a");
  link.download = fileName;
  link.href = url;
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DownloadFileAction = (props: DownloadFileActionProps) => {
  const { icon = <DownloadIcon />, record, ...rest } = props;
  const downloadLinkClick = async () => {
    if (record) {
      const { executionCorrelationId, fileName, configurationId } = record;
      const { body } = await get({
        apiName: "api",
        path: "/tracking/download",
        options: {
          queryParams: {
            correlationId: executionCorrelationId,
            fileName: fileName,
            configurationId: configurationId,
          },
        },
      }).response;
      const url = await body.json();
      triggerDownload(fileName, url?.toString()!);
    }
  };

  return (
    <Button onClick={() => downloadLinkClick()} {...(rest as any)} align="center">
      {icon}
    </Button>
  );
};

export default DownloadFileAction;
