import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { useTranslate, useLocaleState, Title, ToggleThemeButton, useTheme } from "react-admin";

const Settings = () => {
  const translate = useTranslate();
  const [theme, setTheme] = useTheme();
  const [locale, setLocale] = useLocaleState();
  return (
    <Card>
      <Title title={translate("pos.settings")} />
      <CardContent>
        <div style={{ width: "10em", display: "inline-block" }}>{translate("pos.theme.name")}</div>
        <ToggleThemeButton />
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          color={theme === "light" ? "primary" : "secondary"}
          onClick={() => setTheme("light")}
        >
          {translate("pos.theme.light") as any}
        </Button>
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          color={theme === "dark" ? "primary" : "secondary"}
          onClick={() => setTheme("dark")}
        >
          {translate("pos.theme.dark") as any}
        </Button>
      </CardContent>
      <CardContent>
        <div style={{ width: "10em", display: "inline-block" }}>{translate("pos.language")}</div>
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          color={locale === "en" ? "primary" : "secondary"}
          onClick={() => setLocale("en")}
        >
          en
        </Button>
        <Button
          variant="contained"
          sx={{ margin: "1em" }}
          color={locale === "fr" ? "primary" : "secondary"}
          onClick={() => setLocale("fr")}
        >
          fr
        </Button>
      </CardContent>
    </Card>
  );
};

export default Settings;
