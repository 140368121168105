import { Create, required, SimpleForm, TextInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { clearStyleFromHtml } from "@/Tools/helpers";

const CreateForm = (props: any) => (
  <Create {...props}>
    <SimpleForm warnWhenUnsavedChanges validate={props.validate}>
      <TextInput source="name" validate={[required()]} />
      <RichTextInput source="description" validate={[required()]} parse={clearStyleFromHtml} />
      {props.children}
    </SimpleForm>
  </Create>
);

export default CreateForm;
