import {
  BooleanInput,
  FormDataConsumer,
  Loading,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useTranslate,
  useSimpleFormIteratorItem,
  useStore,
  TranslateFunction,
} from "react-admin";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { ConditionalInput } from "@/Components/ConditionalForm";
import { useFormContext } from "react-hook-form";
import { INTEGRATION_TYPES } from "./constants";
import { get } from "aws-amplify/api";
type IntegrationProps = {
  source: string;
  label: string;
};
type IntegrationBasicProps = {
  translate: TranslateFunction;
};

const IntegrationTarget = ({ label }: IntegrationProps) => {
  const translate = useTranslate();

  const form = useFormContext();
  const { index } = useSimpleFormIteratorItem();
  const [useIntegration, setIntegration] = useState(false);

  const resetIntegrationTemplate = () => {
    form.unregister([
      `targets.${index}.integration.template`,
      `targets.${index}.integration.type`,
      `targets.${index}.integration.parameters.path`,
      `targets.${index}.integration.parameters.connection`,
      `targets.${index}.integration.parameters.file_name`,
      `targets.${index}.integration.parameters.content_pattern`,
      `targets.${index}.integration.parameters.use_target_connection`,
      `targets.${index}.integration.parameters.flow_id`,
    ]);
  };

  const toggleIntegration = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    if (!value) resetIntegrationTemplate();
    setIntegration(value);
  };

  return (
    <FormDataConsumer>
      {({ scopedFormData }) => {
        return (
          <>
            <BooleanInput
              aria-test="configuration-target-integration-useIntegration"
              label={label}
              source="integration.use_integration"
              defaultValue={useIntegration}
              onChange={toggleIntegration}
            />
            <ConditionalInput
              values={scopedFormData}
              validate={(values: any) => {
                return values?.integration.use_integration === true;
              }}
            >
              <ReferenceInput
                source="integration.type"
                reference="integrationType"
                label={translate("resources.configuration.fields.target.integrationType")}
                sort={{ field: "name", order: "ASC" }}
              >
                <SelectInput
                  aria-test="configuration-target-integration-type"
                  optionText="name"
                  resettable={true}
                  style={integrationInputStyle}
                  validate={required()}
                />
              </ReferenceInput>

              <ConditionalInput
                values={scopedFormData}
                validate={(values: any) => {
                  return values?.integration?.type === INTEGRATION_TYPES.WRITE_FILE;
                }}
              >
                <WriteFileIntegration translate={translate} />
              </ConditionalInput>
              <ConditionalInput
                values={scopedFormData}
                validate={(values: any) => {
                  return values?.integration?.type === INTEGRATION_TYPES.START_FLOW;
                }}
              >
                <StartFlowIntegration translate={translate} />
              </ConditionalInput>
            </ConditionalInput>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const WriteFileIntegration = ({ translate }: IntegrationBasicProps) => {
  const { index } = useSimpleFormIteratorItem();
  const { getValues, setValue, reset, unregister } = useFormContext();

  const [isLoading, setLoading] = useState(false);
  const [templateId, setTemplateId] = useState<string>("");
  const [template, setTemplate] = useStore<any>(`target.${index}.integration-template`, null);

  useEffect(() => {
    unregister(`integration.parameters.flow_id`);
  }, [unregister]);

  const clearIntegrationParameters = () => {
    const currentValues = getValues();
    setTemplate(null);
    setTemplateId("");
    reset({
      ...currentValues,
      targets: currentValues.targets.with(index, {
        ...currentValues.targets[index],
        integration: {
          ...currentValues.targets[index].integration,
          parameters: {
            path: "",
            connection: "",
            file_name: "",
            content_pattern: "",
            use_target_connection: false,
          },
        },
      }),
    });
  };

  const selectTemplate = async (event: any) => {
    if (!event || event?.target?.value === "") {
      clearIntegrationParameters();
      return;
    }
    setLoading(true);
    const { body } = await get({
      apiName: "api",
      path: `/integrationConfiguration/${event.target.value}`,
    }).response.finally(() => setLoading(false));
    const data = (await body.json()) as Record<string, any>;

    setTemplate(data);
    const currentValues = getValues();
    reset({
      ...currentValues,
      targets: currentValues.targets.with(index, {
        ...currentValues.targets[index],
        integration: {
          type: data?.type,
          template: data?.id,
          parameters: {
            path: data.parameters?.path || "",
            connection: data.parameters?.connection || "",
            file_name: data.parameters?.file_name || "",
            content_pattern: data.parameters?.content_pattern || "",
            use_target_connection: data.parameters?.use_target_connection ?? false,
          },
        },
      }),
    });
  };

  const toggleConnection = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const useTargetConnection = event.target.checked;
      const connectionToUse = useTargetConnection ? "" : template?.parameters?.connection;
      setValue(`targets.${index}.integration.parameters.connection`, connectionToUse, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [index, template, setValue]
  );

  return (
    <FormDataConsumer>
      {() => {
        return (
          <div style={integrationStyle}>
            <ReferenceInput
              source={`integration.template`}
              reference="integrationConfiguration"
              label={translate("resources.configuration.fields.target.integrationTemplate")}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput
                aria-test="configuration-target-integration-template"
                validate={required()}
                optionText="name"
                resettable={true}
                style={integrationInputStyle}
                onChange={selectTemplate}
                defaultValue={templateId}
              />
            </ReferenceInput>
            {isLoading ? (
              <Loading />
            ) : (
              <WriteFileParameters translate={translate} toggleConnection={toggleConnection} />
            )}
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

interface WriteFileParametersProps {
  translate: TranslateFunction;
  toggleConnection: (...event: any[]) => void;
}
const WriteFileParameters = ({ translate, toggleConnection }: WriteFileParametersProps) => {
  return (
    <FormDataConsumer>
      {() => {
        return (
          <>
            <TextInput
              aria-test="configuration-target-integration-path"
              source={`integration.parameters.path`}
              label={translate("resources.configuration.fields.target.integrationPath")}
              validate={required()}
              style={integrationInputStyle}
            />
            <TextInput
              aria-test="configuration-target-integration-filename"
              source={`integration.parameters.file_name`}
              label={translate("resources.configuration.fields.target.integrationFilename")}
              validate={required()}
              style={integrationInputStyle}
            />
            <TextInput
              aria-test="configuration-target-integration-contentPattern"
              source={`integration.parameters.content_pattern`}
              label={translate("resources.configuration.fields.target.integrationContentPattern")}
              validate={required()}
              style={integrationInputStyle}
            />

            <BooleanInput
              aria-test="configuration-target-integration-useTargetConnection"
              defaultValue={false}
              source={`integration.parameters.use_target_connection`}
              onChange={toggleConnection}
              label={translate(
                "resources.configuration.fields.target.integrationUseCurrentConnection"
              )}
            />

            <ReferenceInput
              source={`integration.parameters.connection`}
              reference="connection"
              label={translate("resources.configuration.fields.target.integrationConnection")}
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput
                aria-test="configuration-target-integration-connection"
                optionText="name"
                readOnly
                style={integrationInputStyle}
                sx={{ minWidth: "10rem" }}
              />
            </ReferenceInput>
          </>
        );
      }}
    </FormDataConsumer>
  );
};

const StartFlowIntegration = ({ translate }: IntegrationBasicProps) => {
  const { unregister } = useFormContext();
  const { index } = useSimpleFormIteratorItem();

  useEffect(() => {
    unregister([
      `targets.${index}.integration.template`,
      `targets.${index}.integration.parameters.path`,
      `targets.${index}.integration.parameters.connection`,
      `targets.${index}.integration.parameters.file_name`,
      `targets.${index}.integration.parameters.content_pattern`,
      `targets.${index}.integration.parameters.use_target_connection`,
    ]);
  }, [unregister, index]);

  return (
    <FormDataConsumer>
      {() => {
        return (
          <div style={integrationStyle}>
            <TextInput
              aria-test="configuration-target-integration-flowId"
              source="integration.parameters.flow_id"
              label={translate("resources.configuration.fields.id")}
              validate={required()}
              style={integrationInputStyle}
            />
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

const integrationStyle = {
  width: "50%",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "flex-start",
  justifyContent: "center",
  borderLeft: "1px solid rgba(0, 0, 0, 0.87)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
  borderBottomLeftRadius: "15px",
  paddingLeft: "1rem",
  marginBottom: "2rem",
};
const integrationInputStyle = {
  minWidth: "20rem",
};
export default IntegrationTarget;
