import {
  BooleanInput,
  required,
  TextInput,
  PasswordInput,
  useTranslate,
  TextField,
  BooleanField,
  Labeled,
  NumberInput,
  NumberField,
} from "react-admin";
import CustomPasswordInput from "@/Components/CustomPasswordInput/CustomPasswordInput";

const PARAMETERS = [
  "host",
  "user",
  "password",
  "privateKey",
  "passphrase",
  "port",
  "secureOptions",
];

const validate = ({ parameters }: any) => {
  const errors: { [key: string]: any } = {};
  if (!parameters || (!parameters.privateKey && !parameters.password)) {
    errors.parameters = {
      privateKey: "resources.connection.errors.privateKey_or_password_required",
      password: "resources.connection.errors.privateKey_or_password_required",
    };
  }
  return errors;
};

type SFTPType = { customSx: Record<string, any> };

const Inputs = ({ customSx }: SFTPType) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        sx={customSx}
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        validate={[required()]}
      />
      <TextInput
        sx={customSx}
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        validate={[required()]}
      />
      <PasswordInput
        sx={customSx}
        source="parameters.password"
        label={translate("resources.connection.fields.password")}
      />
      <CustomPasswordInput
        sx={customSx}
        source="parameters.privateKey"
        label={translate("resources.connection.fields.privateKey")}
        multiline={true}
      />
      <PasswordInput
        sx={customSx}
        source="parameters.passphrase"
        label={translate("resources.connection.fields.passphrase")}
      />
      <NumberInput
        source="parameters.port"
        label={translate("resources.connection.fields.port")}
        validate={[required()]}
        defaultValue={22}
      />
      <BooleanInput
        source="parameters.secureOptions.rejectUnauthorized"
        label={translate("resources.connection.fields.reject_unauthorized")}
        defaultValue={true}
      />
    </>
  );
};

const Fields = ({ customSx }: SFTPType) => {
  const translate = useTranslate();
  return (
    <>
      <Labeled
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        sx={customSx}
      >
        <TextField source="parameters.host" />
      </Labeled>
      <Labeled
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        sx={customSx}
      >
        <TextField source="parameters.user" />
      </Labeled>
      <Labeled
        source="parameters.port"
        label={translate("resources.connection.fields.port")}
        sx={customSx}
      >
        <NumberField source="parameters.port" />
      </Labeled>
      <Labeled
        source="parameters.secureOptions.rejectUnauthorized"
        label={translate("resources.connection.fields.reject_unauthorized")}
        sx={customSx}
      >
        <BooleanField source="parameters.secureOptions.rejectUnauthorized" />
      </Labeled>
    </>
  );
};

export { Inputs, Fields, PARAMETERS, validate };
