import { TextField } from "react-admin";
import ListBase from "../BaseModel/List";

const ListForm = (props: any) => (
  <ListBase {...props}>
    <TextField source="command" />
    <TextField source="default_parameters" sortable={false} />
    <TextField source="default_path" />
  </ListBase>
);

export default ListForm;
