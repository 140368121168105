import Icon from "@mui/icons-material/Https";
import List from "../BaseModel/List";
import Show from "../BaseModel/Show";

const AuthType = {
  list: List,
  show: Show,
  icon: Icon,
};

export default AuthType;
