import * as React from "react";
import { useState } from "react";
import { Menu } from "react-admin";
import { styled } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import classnames from "classnames";
import {
  useTranslate,
  MenuItemLink,
  MenuProps,
  usePermissions,
  useSidebarState,
} from "react-admin";

import SubMenu from "./SubMenu";

import { RootMenuModels, ConfigMenuModels } from "@/Components/Models";
import { accessMenu } from "@/Settings/roles";

type MenuName = "menuConfiguration";

const menuClasses = {
  root: "mft-menu-root",
  open: "mft-menu-open",
  close: "mft-menu-close",
};
const StyledMenu = styled(Menu)(({ theme }) => ({
  [`&.${menuClasses.root}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`&.${menuClasses.open}`]: {
    width: 250,
  },
  [`&.${menuClasses.close}`]: {
    width: 55,
  },
}));

const MFTMenu = ({ dense = false }: MenuProps) => {
  const { isLoading, permissions } = usePermissions();
  const [state, setState] = useState({
    menuConfiguration: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return isLoading ? (
    <p>Loading</p> //waiting for permissions to be loaded in app context
  ) : (
    <StyledMenu 
      className={classnames(menuClasses.root, {
        [menuClasses.open]: open,
        [menuClasses.close]: !open,
      })}
      open={open}
    >
      {" "}
      <Menu.DashboardItem />
      {Object.keys(RootMenuModels).map((key) =>
        accessMenu(permissions, key) ? (
          <Menu.Item
            key={key}
            to={{
              pathname: `/${key}`,
            }}
            primaryText={translate(`resources.${key}.name`)}
            leftIcon={React.createElement(RootMenuModels[key].icon)}
            dense={dense}
          />
        ) : null
      )}
      {accessMenu(permissions, "settings") ? (
        <SubMenu
          handleToggle={() => handleToggle("menuConfiguration")}
          isOpen={state.menuConfiguration}
          name="pos.settings"
          icon={<SettingsIcon />}
          dense={dense}
        >
          {Object.keys(ConfigMenuModels).map((key) => (
            <MenuItemLink
              key={key}
              to={`/${key}`}
              primaryText={translate(`resources.${key}.name`)}
              leftIcon={React.createElement(ConfigMenuModels[key].icon)}
              dense={dense}
              sx={{paddingLeft: open ? "1.75rem" : "default"}}
            />
          ))}
        </SubMenu>
      ) : null}
    </StyledMenu>
  );
};

export default MFTMenu;
