import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  SimpleList,
  EditButton,
  useTranslate,
  usePermissions,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { useMediaQuery } from "@mui/material";
import strip from "@/Tools/stripHtml";
import {
  ListBulkActionButtons,
  useEditNeedActions,
  useNeedCopyWindowActions,
  useSearchFilters,
} from "..";
import SearchInput from "@/Components/Layout/SearchInput";
import { ReactElement } from "react";
import CopyIcon from "@mui/icons-material/FileCopy";
import CopyButton from "@/Components/Models/Configuration/CopyButton";
import { authorizeAction } from "@/Settings/roles";
import BaseExporter from "@/Components/Models/BaseModel/BaseExporter";
import ConfigurationExporter from "@/Components/Models/Configuration/Exporter";
import PollingExporter from "@/Components/Models/Polling/Exporter";
import IntegrationExporter from "@/Components/Models/IntegrationConfiguration/Exporter";

const ListPanel = (props: any) => {
  const record = useRecordContext();
  return <div dangerouslySetInnerHTML={{ __html: record?.description }} />;
};

const useFilters = (filters: ReactElement[] = []) => {
  const translate = useTranslate();
  return [
    <SearchInput source="name" label={translate("search.filter.name")} />,
    <SearchInput source="description" label={translate("search.filter.description")} />,
    ...filters,
  ];
};

const useFiltersSync = (resource: string) => {
  return ["configuration", "tracking"].includes(resource) ? false : true;
};

const usePanel = (props: any, resource: string) => {
  if (resource === "user") return undefined;
  return props.listpanel ?? <ListPanel {...props} />;
};

function getCustomExporter(resource: string) {
  if (resource === "configuration") {
    return ConfigurationExporter;
  }
  if (resource === "polling") {
    return PollingExporter;
  }
  if (resource === "integrationConfiguration") {
    return IntegrationExporter;
  }
  return BaseExporter;
}

const ListForm = (props: any) => {
  const resource = useResourceContext()!;
  const { permissions } = usePermissions();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const editNeedActions =
    useEditNeedActions(resource) && authorizeAction(permissions, resource, "edit");
  const copyNeedActions =
    useNeedCopyWindowActions(resource) && authorizeAction(permissions, resource, "copy");

  const filters = useFilters(props.filters);
  const panel = usePanel(props, resource);
  const { icon = <CopyIcon /> } = props;
  const CustomExporter = getCustomExporter(resource);

  return (
    <List
      {...props}
      exporter={CustomExporter}
      pagination={false}
      syncWithLocation={false}
      filters={useSearchFilters(resource) ? filters : false}
      sort={{ field: "name", order: "ASC" }}
      disableSyncWithStore={useFiltersSync(resource)}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => strip(record.description)}
        />
      ) : (
        <Datagrid
          rowClick={false}
          expand={panel}
          bulkActionButtons={<ListBulkActionButtons roles={permissions} resource={resource} />}
        >
          <TextField source="name" />
          {props.children}
          <ShowButton />
          {editNeedActions && <EditButton />}
          {copyNeedActions && <CopyButton icon={icon} />}
        </Datagrid>
      )}
    </List>
  );
};

export default ListForm;
