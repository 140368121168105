import ListBase from "../BaseModel/List";
import { TextField } from "react-admin";

const ListForm = (props: any) => (
  <ListBase {...props}>
    <TextField source="parameters" />
  </ListBase>
);

export default ListForm;
