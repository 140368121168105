import {
  ArrayInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  SortPayload,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { ConditionalInput } from "@/Components/ConditionalForm";
import FiltersSource from "@/Components/Models/Configuration/SourceFilters";
import { checkTransformationsOrder, scheduleValidation } from "..";
import { clearStyleFromHtml } from "@/Tools/helpers";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";

export function StandardSource() {
  const translate = useTranslate();
  const sortable: SortPayload = { field: "name", order: "ASC" };

  return (
    <StyledContainer className={classes.sourceContainer}>
      <span aria-test="configuration-create-source-description">
        <RichTextInput
          label={translate("resources.configuration.fields.source.description")}
          source="source.description"
          validate={[required()]}
          parse={clearStyleFromHtml}
        />
      </span>
      <ReferenceInput
        label={translate("resources.configuration.fields.source.connection")}
        source="source.connection"
        reference="connection"
        sort={sortable}
      >
        <SelectInput
          aria-test="configuration-create-source-connection"
          optionText="name"
          validate={[required()]}
        />
      </ReferenceInput>
      <BooleanInput
        aria-test="configuration-create-source-enabled"
        label={translate("resources.configuration.fields.source.enabled")}
        source="source.enabled"
        defaultValue={true}
      />
      <TextInput
        aria-test="configuration-create-source-path"
        label={translate("resources.configuration.fields.source.path")}
        source="source.path"
        validate={[required()]}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.application")}
        source="source.application"
        reference="application"
        sort={sortable}
      >
        <SelectInput
          aria-test="configuration-create-source-application"
          optionText="name"
          validate={[required()]}
        />
      </ReferenceInput>
      <TextInput
        aria-test="configuration-create-source-wispGroup"
        label={translate("resources.configuration.fields.source.wisp_group")}
        source="source.wisp_group"
      />
      <TextInput
        aria-test="configuration-create-source-job"
        label={translate("resources.configuration.fields.source.job")}
        source="source.job"
        parse={(value: any) => value}
      />
      <TextInput
        aria-test="configuration-create-source-information"
        label={translate("resources.configuration.fields.source.information")}
        source="source.information"
        parse={(value: any) => value}
      />
      <TextInput
        aria-test="configuration-create-source-regex"
        label={translate("resources.configuration.fields.source.regex")}
        source="source.regex"
        defaultValue={"*"}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.transfert_type")}
        source="source.transfert_type"
        reference="transfertType"
      >
        <SelectInput
          aria-test="configuration-create-source-transfert_type"
          optionText="name"
          validate={[required()]}
        />
      </ReferenceInput>
      <ConditionalInput validate={(values: any) => values?.source?.transfert_type === "MOVE_AFTER"}>
        <TextInput
          aria-test="configuration-create-source-transfert_path"
          label={translate("resources.configuration.fields.source.transfert_path")}
          source="source.transfert_path"
          validate={[required()]}
        />
      </ConditionalInput>
      <ReferenceInput
        label={translate("resources.configuration.fields.source.transfert_mode")}
        source="source.transfert_mode"
        reference="transfertMode"
      >
        <SelectInput
          aria-test="configuration-create-source-transfert_mode"
          optionText="name"
          validate={[required()]}
        />
      </ReferenceInput>
      <NumberInput
        aria-test="configuration-create-source-nb_file"
        label={translate("resources.configuration.fields.source.nb_files_per_transfert")}
        source="source.nb_files_per_transfert"
        defaultValue={-1}
      />
      <ReferenceInput
        label={translate("resources.configuration.fields.source.polling")}
        source="source.polling"
        reference="pollingType"
      >
        <SelectInput
          aria-test="configuration-create-source-polling"
          optionText="name"
          validate={[required()]}
        />
      </ReferenceInput>
      <ConditionalInput validate={(values: any) => values?.source?.polling === "AUTOMATIC"}>
        <TextInput
          aria-test="configuration-create-source-cron"
          source="source.schedule_expression"
          validate={[required(), scheduleValidation]}
          label={translate("resources.configuration.fields.source.schedule_expression")}
        />
      </ConditionalInput>
      <FiltersSource />
      <ArrayInput
        label={translate("resources.configuration.fields.source.transformations")}
        source="source.transformations"
        validate={[checkTransformationsOrder]}
      >
        <SimpleFormIterator>
          <ReferenceInput
            source=""
            reference="transformationType"
            label="resources.configuration.fields.transformation"
          >
            <SelectInput
              aria-test="configuration-create-source-transformation"
              optionText="name"
              validate={[required()]}
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </StyledContainer>
  );
}
