export const formatIntegration = (integration: any) => {
    switch (integration.type) {
      case "WRITE_FILE":
        return {
          use_integration: integration.use_integration,
          type: integration.type,
          template: integration.template,
          parameters: {
            connection: integration.parameters!.connection,
            path: integration.parameters.path,
            file_name: integration.parameters.file_name,
            content_pattern: integration.parameters.content_pattern,
            use_target_connection: integration.parameters.use_target_connection,
          },
        };
      case "START_FLOW":
        return {
          use_integration: integration.use_integration,
          type: integration.type,
          parameters: {
            flow_id: integration.parameters!.flow_id,
          },
        };
      default:
        return integration;
    }
  };