import {
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  Toolbar,
} from "react-admin";
import CreateBase from "../BaseModel/Create";
import MaintenanceArea from "./Maintenance";
import { ParametersInputs, ParametersValidation } from "./Parameters";
import Retry from "./Retry";

const PostToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      type="button"
      transform={(data) => {
        const { parameters, ...rest } = data;
        return {
          ...rest,
          parameters,
        };
      }}
    />
  </Toolbar>
);

const CreateForm = (props: any) => (
  <CreateBase {...props} toolbar={<PostToolbar />} validate={ParametersValidation}>
    <BooleanInput source="enabled" defaultValue={true} />
    <ReferenceInput source="type" reference="connectionType">
      <SelectInput optionText="name" validate={[required()]} />
    </ReferenceInput>
    <FormDataConsumer>
      {({formData}) => {
        return <ParametersInputs parameterType={formData.type} source="parameters" validate={[required()]} />
      }}
    </FormDataConsumer>
    <MaintenanceArea />
    <Retry />
  </CreateBase>
);

export default CreateForm;
