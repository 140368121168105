import { FormDataConsumer } from "react-admin";

const ConditionalInput = (props: any) => {
  return <FormDataConsumer>
    {({formData}) => {
      const toValidate = props.values ?? formData;
      return props.validate(toValidate) ?
      props.children : null
    }}
  </FormDataConsumer>
};

export { ConditionalInput };
