import { AppBar, UserMenu, MenuItemLink, useTranslate, useAuthProvider } from "react-admin";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

const CustomUserMenu = (props: any) => {
  const translate = useTranslate();
  const authProvider = useAuthProvider();

  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/settings"
        primaryText={translate("pos.settings")}
        leftIcon={<SettingsIcon />}
        onClick={props.onClick}
      />
      <MenuItemLink
        to="/"
        leftIcon={<Logout />}
        onClick={() => authProvider?.logout({})}
        primaryText={translate("pos.logout")}
      />
    </UserMenu>
  );
};

const CustomAppBar = (props: any) => {
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        id="react-admin-title"
      />
      <span style={{ flex: 1 }} />
    </AppBar>
  );
};

export default CustomAppBar;
