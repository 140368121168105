//@ts-nocheck
import { stringify } from "query-string";
import { DataProvider, PaginationPayload, SortPayload } from "ra-core";
import { get, post, put, del, ApiError } from "aws-amplify/api";

let cache: { [key: string]: any } = {};

export interface ApiProviderInterface extends DataProvider {
  getOneWithEnv: (resource: string, params: { id: string; env: string }) => Promise<any>;
  enable: (resource: string, params: { ids: string[]; enabled: boolean }) => Promise<any>;
  test: (resource: string, params: { id: string }) => Promise<any>;
  deleteWithChecking: (resource: string, params: { ids: string[] }) => Promise<any>;
}

const ApiProvider = (): ApiProviderInterface => ({
  getList: (resource, params) => {
    if (localStorage.getItem("api.refresh") === "true") {
      cache = {};
      localStorage.setItem("api.refresh", "false");
    }

    const { field, order } = params.sort as SortPayload;
    const useShortResponse = params?.meta?.shortResponse ? true : false;
    const query: {
      sort: string;
      filter: string;
      nextToken?: string;
      page?: number;
      perPage?: number;
    } = {
      sort: JSON.stringify([field, order]),
      filter: JSON.stringify(params.filter),
      nextToken: undefined,
      page: params.pagination?.page,
      perPage: params.pagination?.perPage,
      shortResponse: useShortResponse,
    };

    const { page, perPage } = params.pagination as PaginationPayload;
    const cacheKey = `${query.sort}|${query.filter}|${perPage}`;

    if (!cache[resource] || !cache[resource][cacheKey]) {
      cache[resource] = { [cacheKey]: {} };
    } else {
      const current = cache[resource][cacheKey];
      query.nextToken = current[`${page - 1}`] ? JSON.stringify(current[`${page - 1}`]) : undefined;
    }

    const url = `/${resource}?${stringify(query)}`;

    return get({
      apiName: "api",
      path: url,
    })
      .response.then((response) => response.body.json())
      .then((json: any) => {
        cache[resource][cacheKey][`${page}`] = json.NextToken;
        return {
          data: json.Items,
          total: json.total || 100000,
          nextToken: json.NextToken,
        };
      })
      .catch((error) => {
        throw new Error(`Error fetching list: ${error.message}`);
      });
  },

  getOne: (resource, params) => {
    return get({
      apiName: "api",
      path: `/${resource}/${params.id}`,
    })
      .response.then((response) => response.body.json())
      .then((json) => ({ data: json }))
      .catch((error) => {
        throw new Error(`Error fetching one: ${error.message}`);
      });
  },

  getOneWithEnv: (resource: string, params: { id: string; env: string }) => {
    return get({
      apiName: "api",
      path: `/${resource}/copy/${params.id}/${params.env}`,
    })
      .response.then((response) => response.body.json())
      .then((json) => ({ data: json }))
      .catch((error) => {
        throw new Error(`Error get one with env: ${error.message}`);
      });
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `/${resource}?${stringify(query)}`;

    return get({
      apiName: "api",
      path: url,
    })
      .response.then((response) => response.body.json())
      .then((json) => ({ data: json?.Items }))
      .catch((error) => {
        throw new Error(`Error fetching many: ${error.message}`);
      });
  },

  getManyReference: (resource, params) => {
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `/${resource}?${stringify(query)}`;

    return get({
      apiName: "api",
      path: url,
    })
      .response.then((response) => response.body.json())
      .then((json) => ({
        data: json.Items,
        total: json.total ?? json.Items.length,
      }))
      .catch((error) => {
        throw new Error(`Error fetching many reference: ${error.message}`);
      });
  },

  update: (resource, params) => {
    return put({
      apiName: "api",
      path: `/${resource}/${params.id}`,
      options: {
        body: params.data,
      },
    })
      .response.then((response) => response.body.json())
      .then((json) => ({ data: json }))
      .catch((error) => {
        throw new Error(`Error updating: ${error.message}`);
      });
  },

  updateMany: (resource, params) => {
    const promises = params.ids.map(
      (id) =>
        put({
          apiName: "api",
          path: `/${resource}/${id}`,
          options: {
            body: params.data,
          },
        }).response
    );

    return Promise.all(promises)
      .then((responses) => Promise.all(responses.map((r) => r.body.json())))
      .then((results) => ({ data: results.map((json) => json?.id) }))
      .catch((error) => {
        throw new Error(`Error updating many: ${error.message}`);
      });
  },

  create: (resource, params) => {
    return post({
      apiName: "api",
      path: `/${resource}`,
      options: {
        body: params.data,
      },
    })
      .response.then((response) => response.body.json())
      .then((json) => ({
        data: { ...params.data, id: json.id },
      }))
      .catch((error) => {
        throw new Error(`Error creating: ${error.message}`);
      });
  },

  delete: (resource, params) => {
    return del({
      apiName: "api",
      path: `/${resource}/${params.id}`,
      options: {
        headers: { "Content-Type": "text/plain" },
      },
    }).response.catch((error) => {
      throw new Error(`Error deleting: ${error.message}`);
    });
  },

  deleteMany: (resource, params) => {
    const promises = params.ids.map(
      (id) =>
        del({
          apiName: "api",
          path: `/${resource}/${id}`,
          options: {
            headers: { "Content-Type": "text/plain" },
          },
        }).response
    );

    return Promise.all(promises)
      .then((responses) => ({ data: responses.map((r) => r.statusCode) }))
      .catch((error) => {
        throw new Error(`Error deleting many: ${error.message}`);
      });
  },

  deleteWithChecking: (resource, params) => {
    return post({
      apiName: "api",
      path: `/${resource}/deleteWithChecking`,
      options: {
        body: params,
      },
    })
      .response.then((response) => response.body.json())
      .then((json) => ({ data: json }))
      .catch((error) => {
        throw new Error(`Error deleting with checking: ${error.message}`);
      });
  },

  enable: (resource, params) => {
    return post({
      apiName: "api",
      path: `/${resource}/enable`,
      options: {
        body: params,
      },
    })
      .response.then((response) => response.body.json())
      .then((json) => ({ data: json }))
      .catch((error) => {
        throw new Error(`Error enabling: ${error.message}`);
      });
  },

  test: (resource, params) => {
    return get({
      apiName: "api",
      path: `/${resource}/${params.id}/test?auto=false`,
    })
      .response.then((response) => response.body.json())
      .then((json) => ({ data: json }))
      .catch((error) => {
        if (error instanceof ApiError) {
          return Promise.reject(
            error.response?.body ? JSON.parse(error.response?.body) : error.response
          );
        }
        return Promise.reject(error);
      });
  },
});

export default ApiProvider;
