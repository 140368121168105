import {
  BooleanInput,
  required,
  TextInput,
  PasswordInput,
  useTranslate,
  TextField,
  BooleanField,
  Labeled,
  NumberInput,
  NumberField,
} from "react-admin";

const FTPS_PARAMETERS = ["host", "user", "password", "port", "secureOptions"];
const FTP_PARAMETERS = ["host", "user", "password", "port"];

type FTPType = { customSx: Record<string, any>, disableReject: boolean }

const Inputs = ({ customSx, disableReject }: FTPType) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        sx={customSx}
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        validate={[required()]}
      />
      <TextInput
        sx={customSx}
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        validate={[required()]}
      />
      <PasswordInput
      sx={customSx}
        source="parameters.password"
        label={translate("resources.connection.fields.password")}
        validate={[required()]}
      />
      <NumberInput
        sx={customSx}
        source="parameters.port"
        label={translate("resources.connection.fields.port")}
        validate={[required()]}
        defaultValue={21}
      />
      {!disableReject && 
        <BooleanInput
        sx={customSx}
          source="parameters.secureOptions.rejectUnauthorized"
          label={translate("resources.connection.fields.reject_unauthorized")}
          defaultValue={true}
        />
      }
    </>
  );
};

const Fields = ({ customSx, disableReject }: FTPType) => {
  const translate = useTranslate();
  return (
    <>
      <Labeled
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        sx={customSx}
      >
        <TextField source="parameters.host" />
      </Labeled>
      <Labeled
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        sx={customSx}
      >
        <TextField source="parameters.user" />
      </Labeled>
      <Labeled
        source="parameters.port"
        label={translate("resources.connection.fields.port")}
        sx={customSx}
      >
        <NumberField source="parameters.port" />
      </Labeled>
      {!disableReject && 
        <Labeled
          source="parameters.secureOptions.rejectUnauthorized"
          label={translate("resources.connection.fields.reject_unauthorized")}
          sx={customSx}
        >
          <BooleanField source="parameters.secureOptions.rejectUnauthorized" />
        </Labeled>
      }
    </>
  );
};

export { Inputs, Fields, FTP_PARAMETERS, FTPS_PARAMETERS };
