import { get } from "aws-amplify/api";
import { stringify } from "query-string";

export function cleanText(text: string): string {
  if (!text) return text;
  return text.replace(",", ";");
}

export async function getConnections(ids: string[]) {
  if (!ids.length) return {};
  const query = {
    filter: JSON.stringify({
      id: ids,
    }),
  };
  let response = await get({
    apiName: "api",
    path: `/connection?${stringify(query)}`,
  }).response;
  const connections = (await response.body.json()) as Record<string, any>;
  return connections.Items.map((item: any) => {
    return { [item.id]: item.name };
  }).reduce((prev: any, next: any) => {
    return Object.assign(prev, next);
  }, {});
}

export async function getUsers(ids: string[]) {
  if (!ids.length) return {};
  const query = {
    filter: JSON.stringify({
      id: ids,
    }),
  };
  let response = await get({
    apiName: "api",
    path: `/user?${stringify(query)}`,
  }).response;
  const users = (await response.body.json()) as Record<string, any>;
  return users.Items.map((item: any) => {
    return { [item.id]: item.name };
  }).reduce((prev: any, next: any) => {
    return Object.assign(prev, next);
  }, {});
}

export async function getRoles(ids: string[]) {
  if (!ids.length) return {};
}
