import { Button, useNotify, ButtonProps, useTranslate, useDataProvider } from "react-admin";
import { useMutation } from "@tanstack/react-query";
import SpeedIcon from "@mui/icons-material/Speed";
import { ReactElement } from "react";
import { RaRecord, useRecordContext, useResourceContext } from "ra-core";

interface Props {
  basePath?: string;
  icon?: ReactElement;
  label?: string;
  record?: RaRecord;
  scrollToTop?: boolean;
}

type TestButtonProps = Props & ButtonProps;

const TestButton = (props: TestButtonProps) => {
  const { icon = <SpeedIcon />, label = "common.actions.test.label", ...rest } = props;
  const record = useRecordContext();
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  const notify = useNotify();
  const { mutate } = useMutation({
    mutationFn: async () => {
      return dataProvider.test(resource, {
        id: record?.id,
      });
    },
    onSuccess: (result) => {
      let db = result.data;
      notify("common.actions.test.success", {
        type: "info",
        messageArgs: {
          id: record?.name,
          connectivityTest: db.connectivityTest ? "OK" : "KO",
          listingFile: db.listingFile ? "OK" : "KO",
        },
      });
    },
    onError: (error: any) => {
      let detail;
      if (error.detail instanceof Object) {
        detail = error.detail.errorMessage;
      } else if (typeof error.detail === "string") {
        detail = error.detail;
      } else if (!error.detail) {
        detail = translate("common.actions.test.timeout");
      }
      notify("common.actions.test.failure", {
        type: "warning",
        messageArgs: { id: record?.id, error: detail },
      });
    },
    retry: false,
  });

  return (
    <Button label={label} onClick={() => mutate()} {...(rest as any)}>
      {icon}
    </Button>
  );
};

export default TestButton;
