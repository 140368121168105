import { Chip } from "@mui/material";
import { useRecordContext } from "react-admin";

const style = {
  main: {
    display: "flex",
    flexFlow: "row nowrap",
    marginTop: 8,
    marginBottom: 0,
  },
  chip: { margin: 4 },
};

const SimpleChipField = (props: any) => {
  const record = useRecordContext();
  return record ? (
    <span style={style.main}>
      {record.roles.map((role: string) => (
        <Chip key={JSON.stringify(record)} label={role} style={style.chip} />
      ))}
    </span>
  ) : null;
};

export default SimpleChipField;
