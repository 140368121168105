import { ConditionalInput } from "@/Components/ConditionalForm";
import {
  ArrayInput,
  BooleanInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";
import { Box } from "@mui/material";
import { scheduleValidation } from "..";
import { StyledContainer, classes } from "@/Components/CustomStyled/Containers";

const MaintenanceArea = () => {
  const translate = useTranslate();
  return (
    <StyledContainer className={classes.formArea}>
      <BooleanInput
        source="maintenance_enabled"
        defaultValue={false}
        label={translate("resources.connection.fields.maintenance_enabled")}
      />
      <ConditionalInput validate={(values: any) => values?.maintenance_enabled === true}>
        <span className="MuiTypography-body1">
          {translate("resources.connection.fields.maintenance_note_utc")}
        </span>
        <ArrayInput
          source="maintenance_schedules"
          label={undefined}
          validate={required()}
          sx={{ margin: "1rem 0" }}
        >
          <SimpleFormIterator>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"1rem"}
            >
              <TextInput
                sx={{ margin: "0.75rem 0 0 0.75rem" }}
                source="start_schedule"
                validate={[required(), scheduleValidation]}
                label={translate(
                  "resources.connection.fields.mainetance_start_schedule_expression"
                )}
              />
              <TextInput
                sx={{ margin: "0.75rem 0 0 0.75rem" }}
                source="end_schedule"
                validate={[required(), scheduleValidation]}
                label={translate("resources.connection.fields.mainetance_end_schedule_expression")}
              />
            </Box>
          </SimpleFormIterator>
        </ArrayInput>
      </ConditionalInput>
    </StyledContainer>
  );
};

export default MaintenanceArea;
