import { styled } from "@mui/material/styles";
import { NumberField } from "react-admin";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const green = "#caffca";
const red = "#ff7171";
const gray = "#9f9f9f";
const yellow = "yellow";
const lightblue = "#A6CDDC";
const orange = "orange";
const darkgray = "#DDDDDD";
const cellStyles = {
  display: "block",
  padding: "12px",
  borderRadius: "4px",
  margin: "-5px",
  color: "black",
  fontWeight: 600,
};

const classes = {
  textColor: "mft-cstm-tableTextColor",
  nocolor: "mft-cstm-nocolorCell",
  gray: "mft-cstm-grayCell",
  darkgray: "mft-cstm-darkgrayCell",
  green: "mft-cstm-greenCell",
  lightblue: "mft-cstm-lightblueCell",
  yellow: "mft-cstm-yellowCell",
  orange: "mft-cstm-orangeCell",
  red: "mft-cstm-redCell",
  nocolorRow: "mft-cstm-nocolorCell",
  grayRow: "mft-cstm-grayCell",
  darkgrayRow: "mft-cstm-darkgrayCell",
  greenRow: "mft-cstm-greenCell",
  lightblueRow: "mft-cstm-lightblueCell",
  yellowRow: "mft-cstm-yellowCell",
  orangeRow: "mft-cstm-orangeCell",
  redRow: "mft-cstm-redCell",
};

const StyledNumberField = styled(NumberField)(() => ({
  [`&.${classes.nocolor}`]: {
    ...cellStyles,
    color: "unset",
  },
  [`&.${classes.green}`]: {
    ...cellStyles,
    backgroundColor: green,
  },
  [`&.${classes.lightblue}`]: {
    ...cellStyles,
    backgroundColor: lightblue,
  },
  [`&.${classes.red}`]: {
    ...cellStyles,
    backgroundColor: red,
  },
  [`&.${classes.gray}`]: {
    ...cellStyles,
    backgroundColor: gray,
  },
  [`&.${classes.yellow}`]: {
    ...cellStyles,
    backgroundColor: yellow,
  },
  [`&.${classes.orange}`]: {
    ...cellStyles,
    backgroundColor: orange,
  },
  [`&.${classes.darkgray}`]: {
    ...cellStyles,
    backgroundColor: darkgray,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  [`&.${classes.nocolorRow}`]: {
    backgroundColor: "unset",
  },
  [`&.${classes.greenRow}`]: {
    backgroundColor: green,
  },
  [`&.${classes.lightblueRow}`]: {
    backgroundColor: lightblue,
  },
  [`&.${classes.redRow}`]: {
    backgroundColor: red,
  },
  [`&.${classes.grayRow}`]: {
    backgroundColor: gray,
  },
  [`&.${classes.yellowRow}`]: {
    backgroundColor: yellow,
  },
  [`&.${classes.orangeRow}`]: {
    backgroundColor: orange,
  },
  [`&.${classes.darkgrayRow}`]: {
    backgroundColor: darkgray,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${classes.textColor}`]: {
    color: "black",
    fontWeight: 600,
  },
}));

export { StyledNumberField, StyledTableCell, StyledTableRow, classes };
