import { styled } from "@mui/material/styles";
import { copyTextToClipboard } from "@/Tools/helpers";

export const classes = {
  field: "mft-cstm-ctcField",
};
const style = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "fit-content",
  fontSize: "12px",
  color: "black",
  backgroundColor: "white",
  padding: "3px",
};
const CopyField = (props: any) => {
  return (
    <>
      <span
        id="copy-tooltip"
        className={props.className}
        onClick={(event: any) => {
          console.log("1. Click event : ", event);
          copyTextToClipboard(event.target.innerText as string);
          const element = document.getElementById("copy-tooltip");
          element!.title = "Copied !";
        }}
        onMouseEnter={() => {
          const element = document.getElementById("copy-tooltip");
          element!.title = "Copy";
        }}
        title="Copy"
      >
        {props.children}
      </span>
    </>
  );
};

export const CopyToClipboard = styled(CopyField)(() => ({
  [`&.${classes.field}`]: {
    ...style,
    "&:hover": {
      borderRadius: "5px",
      backgroundColor: "#F0F0F0",
    },
    "&:active": {
      backgroundColor: "#CCC",
    },
  },
}));
