import { SaveButton, Toolbar, SimpleForm, TextInput, Edit, EmailField, Labeled } from "react-admin";
import RoleInput from "./RoleInput";
import RoleAppInput from "./RoleAppInput";
import SimpleChipField from "./SimpleChipField";

const UpdateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const EditForm = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<UpdateToolbar />} validate={props.validate}>
        <TextInput source="name" readOnly />
        <EmailField source="email" />
        <Labeled label={props.label}>
          <SimpleChipField />
        </Labeled>
        <RoleInput source="roles" label="resources.user.fields.roles" />
        <RoleAppInput source="roles" label="resources.user.fields.roles" />
      </SimpleForm>
    </Edit>
  );
};

export default EditForm;
