import {
  DateField,
  Labeled,
  ReferenceField,
  TextField,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Box } from "@mui/material";
import ShowBase from "../BaseModel/Show";
import { INTEGRATION_TYPES } from "../Configuration/constants";

const IntegrationParametersField = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return record?.type === INTEGRATION_TYPES.WRITE_FILE ? (
    <Box display={"flex"} flexDirection={"column"} gap={"0.5rem"}>
      <Labeled label={translate("resources.integrationConfiguration.fields.parameters.connection")}>
        <ReferenceField source="parameters.connection" reference="connection" link="show">
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
      <Labeled label={translate("resources.integrationConfiguration.fields.parameters.path")}>
        <TextField source="parameters.path" />
      </Labeled>
      <Labeled label={translate("resources.integrationConfiguration.fields.parameters.file_name")}>
        <TextField source="parameters.file_name" />
      </Labeled>
      <Labeled
        label={translate("resources.integrationConfiguration.fields.parameters.content_pattern")}
      >
        <TextField source="parameters.content_pattern" />
      </Labeled>
    </Box>
  ) : (
    <ReferenceField source="parameters.flow_id" reference="configuration" link="show">
      <TextField source="id" />
    </ReferenceField>
  );
};
const ShowForm = (props: any) => {
  return (
    <ShowBase {...props}>
      <ReferenceField source="type" reference="integrationType" link="show">
        <TextField source="name" />
      </ReferenceField>
      <IntegrationParametersField />
      <ReferenceField source="created_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" showTime={true} />
      <ReferenceField source="updated_by" reference="user" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="updated_at" showTime={true} />
    </ShowBase>
  );
};

export default ShowForm;
