/**
 * Check if a filename or filename pattern contains unauthorized characters
 *
 * @param {string} filename - Filename input
 * @returns {boolean} - if the filename is valid or not
 */
function checkFilename(filename: string): boolean {
  const wrongChars = ["<", ">", ":", "|", "?", "*", '"', "/", "\\"];
  return filename.split("").every((char) => !wrongChars.includes(char));
}

/**
 * Validate the filename input
 *
 * @param {string} value - input value
 * @returns
 */
export function validateFilename(value: string) {
  if (!checkFilename(value)) {
    return "resources.configuration.fields.filename_error";
  }
  return;
}

/**
 * Transform a text input in uppercase
 *
 * @param {string} text text input
 * @returns {string} - uppercase text
 */
export function toUpperCase(text: string): string {
  return text.toUpperCase();
}

/**
 * Remove duplicate strings in an array
 *
 * @param {string[]} list - Array of strings
 * @returns {string[]} - Array of strings with no duplicated values
 */
export function dropDuplicates(list: string[]): string[] {
  return list.filter((value, index) => {
    return list.indexOf(value) === index;
  });
}

/**
 * Remove empty strings and null values (null | undefined) from an array
 *
 * @param {string[]} list - Array of strings with possibly empty or null values (null | undefined)
 * @returns {string[]} - Array of strings
 */
export function removeEmptyOrNullString(list: string[]): string[] {
  return list.filter((value: string) => !!value);
}

/**
 * Remove inline style from HTML string
 *
 * @param {string} html - HTML string
 * @returns {string} - Style removed HTML string
 */
export function clearStyleFromHtml(html: string): string {
  var strippedHtml = html.replace(/style\s*=\s*(['"])[^'"]*\1/g, "");
  const result = strippedHtml;
  return result;
}

/**
 * Check if a value is defined and not empty
 *
 * @param value - value
 * @returns {boolean}
 */
export function isDefined<T>(value: T | undefined): value is T {
  if (value === null || value === undefined) return false;
  if (Array.isArray(value) && value.length === 0) return false;
  if (value.constructor === Object && Object.keys(value).length === 0) return false;

  return true;
}

/**
 * Recursive function to remove empty, null or undefined values from an object
 *
 * @param {any} value
 * @returns {any | undefined}
 */
export function cleanObject(value: any): any | undefined {
  if (value === "" || value === null || value === undefined) {
    return undefined;
  }
  if (typeof value != "object") return value;
  const cleaned = Object.entries(value).reduce((acc, [key, value]) => {
    const cleanValue = cleanObject(value);
    if (cleanValue !== undefined) {
      acc[key] = cleanValue;
    }
    return acc;
  }, {} as any);
  return Object.keys(cleaned).length ? cleaned : undefined;
}

export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}
