import Icon from "@mui/icons-material/LockOutlined";
import List from "./List";
import Show from "./Show";

const Softlock = {
  list: List,
  show: Show,
  icon: Icon,
};

export default Softlock;
