import {
  required,
  TextInput,
  PasswordInput,
  useTranslate,
  TextField,
  Labeled,
} from "react-admin";

const PARAMETERS = ["host", "user", "password", "port", "secureOptions"];

type HTTPType = { customSx: Record<string, any> };

const Inputs = ({ customSx }: HTTPType) => {
  const translate = useTranslate();
  return (
    <>
      <TextInput
        sx={customSx}
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        validate={[required()]}
      />
      <TextInput
        sx={customSx}
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        validate={[required()]}
      />
      <PasswordInput
        sx={customSx}
        source="parameters.password"
        label={translate("resources.connection.fields.password")}
        validate={[required()]}
      />
    </>
  );
};

const Fields = ({ customSx }: HTTPType) => {
  const translate = useTranslate();
  return (
    <>
      <Labeled
        source="parameters.host"
        label={translate("resources.connection.fields.host")}
        sx={customSx}
      >
        <TextField source="parameters.host" />
      </Labeled>
      <Labeled
        source="parameters.user"
        label={translate("resources.connection.fields.user")}
        sx={customSx}
      >
        <TextField source="parameters.user" />
      </Labeled>
    </>
  );
};

export { Inputs, Fields, PARAMETERS };
